import { createClient } from "graphql-ws";
import { createSubjectHook } from "./utils/observers";
import { IS_LOCAL } from "./server-select";

const [useServerState, setServerState] = createSubjectHook<
  "CONNECTED" | "DISCONNECTED" | undefined
>(undefined);

export { useServerState };

export const createWSClient = (apiUrl: string) => {
  const client = createClient({
    url: `${IS_LOCAL ? "ws://" : "wss://"}${apiUrl}`,
    connectionParams: localStorage.getItem("token")
      ? {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      : undefined,
    keepAlive: 10_000,
  });

  client.on("connected", () => {
    setServerState("CONNECTED");
  });
  client.on("closed", () => {
    setServerState("DISCONNECTED");
  });

  return client;
};
