export interface Schema {
  type: string;
  description: string;
  required: boolean;
  isArray?: boolean;
  items?: Record<string, Schema>;
  possibleValues?: string[];
}

export interface TagInfo {
  tag: string;
  description: string;
  scopeOutputs?: Record<string, Schema>;
  hashInputs?: (Schema & { name: string })[];
  paramValue?: {
    description: string;
    required: boolean;
  };
}

export const TagDefinitions: TagInfo[] = [
  {
    tag: "favicon",
    description: "Change the favicon of the page",
    paramValue: {
      description: "The URL of the new favicon",
      required: true,
    },
  },
  {
    tag: "title",
    description: "Set the title of the page",
    paramValue: {
      description: "The new title of the page",
      required: true,
    },
  },
  {
    tag: "form",
    description: "Renders a dynamic form",
    paramValue: {
      description:
        "The type of form to render, available types: update-local-player-profile, register-tournament-team, confirm-tournament-team, confirm-tournament-attendance, delete-tournament-team",
      required: true,
    },
  },
  {
    tag: "stylesheet",
    description: "Injects a stylesheet into the head of the document",
    paramValue: {
      required: true,
      description: "The URL of the stylesheet to inject",
    },
  },
  {
    tag: "nav-link",
    description: "Create a link to a page",
    hashInputs: [
      {
        type: "string",
        name: "to",
        required: true,
        description: "The path to the page",
      },
    ],
  },
  {
    tag: "logs",
    description: "",
    scopeOutputs: {
      result: {
        type: "Logs!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[LogEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[Log!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[Log!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              type: {
                type: "LogType!",
                description: "",
                required: true,
                isArray: false,
                items: {},
                possibleValues: ["WEBHOOK_CALL", "WEBHOOK_CALL_ERROR"],
              },
              resourceId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              data: {
                type: "LogData!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  oldValue: {
                    type: "JSONObject",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  newValue: {
                    type: "JSONObject!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  author: {
                    type: "String",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  authorType: {
                    type: "LogAuthorType!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                    possibleValues: ["SYSTEM", "USER", "APP"],
                  },
                },
              },
              organizationId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {
              hasNextPage: {
                type: "Boolean!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              hasPreviousPage: {
                type: "Boolean!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              startCursor: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              endCursor: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
            },
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "page",
        type: "PageInfo",
        description: "",
        required: false,
        isArray: false,
        items: {
          first: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          after: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      {
        name: "resourceId",
        type: "ID",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      {
        name: "types",
        type: "[LogType!]",
        description: "",
        required: false,
        isArray: true,
        items: {},
      },
    ],
  },
  {
    tag: "getMyAccount",
    description: "",
    scopeOutputs: {
      result: {
        type: "Account!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "ID!",
            description: "The ID of the account",
            required: true,
            isArray: false,
            items: {},
          },
          email: {
            type: "EmailAddress",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          permissions: {
            type: "[GroupPermission!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              resources: {
                type: "[String!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          profiles: {
            type: "[Player!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              username: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              profilePicUrl: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              description: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              ownerId: {
                type: "ID",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              organizationCustomId: {
                type: "ID",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              identities: {
                type: "[AccountIdentity!]",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              owner: {
                type: "Account",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              customFields: {
                type: "[PropertyConfigWithValue!]!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  property: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  name: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  type: {
                    type: "PropertyType!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                    possibleValues: [
                      "DATE",
                      "COUNTRY",
                      "STRING",
                      "BOOLEAN",
                      "PHONE",
                    ],
                  },
                  required: {
                    type: "Boolean!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  order: {
                    type: "Float!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  unique: {
                    type: "Boolean!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  public: {
                    type: "Boolean",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  visibility: {
                    type: "PropertyVisibility",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                    possibleValues: [
                      "PUBLIC",
                      "OWNER",
                      "OWNER_OR_PERMISSION",
                      "WITH_PERMISSION",
                    ],
                  },
                  editability: {
                    type: "ProperyEditability",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                    possibleValues: ["ALWAYS", "ONE_TIME", "WITH_PERMISSION"],
                  },
                  value: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
          identities: {
            type: "[AccountIdentity!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              providerId: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              accountId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              organizationIdentityProviderId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              properties: {
                type: "[AccountIdentityProperty!]!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  property: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  value: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              organizationIdentityProvider: {
                type: "PublicOrganizationIdentityProvider",
                description: "",
                required: false,
                isArray: false,
                items: {
                  id: {
                    type: "ID!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  name: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  description: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  icon: {
                    type: "String",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  allowLogin: {
                    type: "Boolean!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  authorizationUrl: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "configuration",
    description: "",
    scopeOutputs: {
      result: {
        type: "Configuration!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "ConfigurationProperties!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          createdById: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          organizationId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "leaderboardCurrentPlayerRanking",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeasonBucketScore!",
        description: "",
        required: true,
        isArray: false,
        items: {
          rank: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          lastScore: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          lastRank: {
            type: "Int",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          score: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          leaderboardSeasonBucketId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          playerProfileId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          player: {
            type: "Player!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "LeaderboardGetPlayerRankingInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          leaderboardId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          playerId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          seasonId: {
            type: "ID",
            description:
              "If not set, the current active season will be selected",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "leaderboards",
    description: "",
    scopeOutputs: {
      result: {
        type: "Leaderboards!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[LeaderboardEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[Leaderboard!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[Leaderboard!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "ID!",
                description: "The ID of the leaderboard",
                required: true,
                isArray: false,
                items: {},
              },
              organizationId: {
                type: "ID!",
                description:
                  "The ID of the organization this leaderboard belongs to",
                required: true,
                isArray: false,
                items: {},
              },
              ownerId: {
                type: "ID!",
                description: "The ID of the creator of this leaderboard",
                required: true,
                isArray: false,
                items: {},
              },
              name: {
                type: "String!",
                description: "The name of the leaderboard",
                required: true,
                isArray: false,
                items: {},
              },
              description: {
                type: "String!",
                description: "The description of the leaderboard",
                required: true,
                isArray: false,
                items: {},
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              seasons: {
                type: "LeaderboardSeasons!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  edges: {
                    type: "[LeaderboardSeasonEdge!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      cursor: {
                        type: "String!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      node: {
                        type: "[LeaderboardSeason!]!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                    },
                  },
                  nodes: {
                    type: "[LeaderboardSeason!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      id: {
                        type: "ID!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      leaderboardId: {
                        type: "ID!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      name: {
                        type: "String!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      start: {
                        type: "DateTime!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      end: {
                        type: "DateTime!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      startingScore: {
                        type: "Float!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      createdAt: {
                        type: "DateTime!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      updatedAt: {
                        type: "DateTime!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      buckets: {
                        type: "LeaderboardSeasonBuckets!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {
                          edges: {
                            type: "[LeaderboardSeasonBucketEdge!]!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {
                              cursor: {
                                type: "String!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              node: {
                                type: "[LeaderboardSeasonBucket!]!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                            },
                          },
                          nodes: {
                            type: "[LeaderboardSeasonBucket!]!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {
                              id: {
                                type: "ID!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              seasonId: {
                                type: "ID!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              name: {
                                type: "String!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              minScore: {
                                type: "Float!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              scoreCalculationType: {
                                type: "LeaderboardScoreCalculationType!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                                possibleValues: ["SCORE"],
                              },
                              createdAt: {
                                type: "DateTime!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              updatedAt: {
                                type: "DateTime!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              scores: {
                                type: "LeaderboardSeasonBucketScores!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {
                                  edges: {
                                    type: "[LeaderboardSeasonBucketScoreEdge!]!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {
                                      cursor: {
                                        type: "String!",
                                        description: "",
                                        required: true,
                                        isArray: false,
                                        items: {},
                                      },
                                      node: {
                                        type: "[LeaderboardSeasonBucketScore!]!",
                                        description: "",
                                        required: true,
                                        isArray: false,
                                        items: {},
                                      },
                                    },
                                  },
                                  nodes: {
                                    type: "[LeaderboardSeasonBucketScore!]!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {},
                                  },
                                  pageInfo: {
                                    type: "ResponsePageInfo!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {},
                                  },
                                  totalCount: {
                                    type: "Float",
                                    description: "",
                                    required: false,
                                    isArray: false,
                                    items: {},
                                  },
                                },
                              },
                            },
                          },
                          pageInfo: {
                            type: "ResponsePageInfo!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                          totalCount: {
                            type: "Float",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                    },
                  },
                  pageInfo: {
                    type: "ResponsePageInfo!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  totalCount: {
                    type: "Float",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "leaderboard",
    description: "",
    scopeOutputs: {
      result: {
        type: "Leaderboard!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "currentLeaderboardSeason",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeason!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "leaderboardId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "leaderboardSeasons",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeasons!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "leaderboardId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "leaderboardSeason",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeason!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "leaderboardSeasonBuckets",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeasonBuckets!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "seasonId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "leaderboardSeasonBucket",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeasonBucket!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "organization",
    description: "",
    scopeOutputs: {
      result: {
        type: "Organization!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          ownerId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "OrganizationConfiguration!",
            description: "",
            required: true,
            isArray: false,
            items: {
              customFields: {
                type: "[Property!]!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  property: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  name: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  type: {
                    type: "PropertyType!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  required: {
                    type: "Boolean!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  order: {
                    type: "Float!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  unique: {
                    type: "Boolean!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  public: {
                    type: "Boolean",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  visibility: {
                    type: "PropertyVisibility",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  editability: {
                    type: "ProperyEditability",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
          tournaments: {
            type: "[Tournament!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              title: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              description: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              startAt: {
                type: "DateTime",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              endAt: {
                type: "DateTime",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              startRegistrationsAt: {
                type: "DateTime",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              endRegistrationsAt: {
                type: "DateTime",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              organizationId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              configuration: {
                type: "TournamentConfiguration",
                description: "",
                required: false,
                isArray: false,
                items: {
                  type: {
                    type: "ConfigurationType!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                    possibleValues: ["TOURNAMENT", "STEP"],
                  },
                  teamMinSize: {
                    type: "Float",
                    description:
                      "Minimum team size to register to the tournament",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  teamMaxSize: {
                    type: "Float",
                    description:
                      "Maximum team size to register to the tournament",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  teamsCount: {
                    type: "Float",
                    description: "Maximum number of registered teams",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  teamStatusAfterRegistration: {
                    type: "TeamStatusAfterRegistration",
                    description:
                      "Status of the team after registration, REGISTERED is the default state, VALIDATED means that the team has been validated by an admin, AWAITING_FOR_PAYMENT means that the team has to pay to be validated",
                    required: false,
                    isArray: false,
                    items: {},
                    possibleValues: [
                      "REGISTERED",
                      "AWAITING_FOR_PRESENCE_CONFIRMATION",
                      "AWAITING_FOR_PAYMENT",
                    ],
                  },
                  registrationConditions: {
                    type: "RegistrationConditions",
                    description:
                      "Condition rules to register to the tournament",
                    required: false,
                    isArray: false,
                    items: {
                      teamConditions: {
                        type: "[TeamCondition!]!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {
                          property: {
                            type: "String!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                          stringCondition: {
                            type: "StringCondition",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {
                              conditionType: {
                                type: "StringConditionType!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                                possibleValues: ["EQ", "NEQ"],
                              },
                              value: {
                                type: "String!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                            },
                          },
                          numericCondition: {
                            type: "TeamNumericCondition",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {
                              aggregationType: {
                                type: "AggregationType",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {},
                                possibleValues: ["SUM", "AVG", "MIN", "MAX"],
                              },
                              propertySource: {
                                type: "PlayerConditionDataSource",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {},
                              },
                              propertySourceId: {
                                type: "ID",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {},
                              },
                              conditionType: {
                                type: "NumberConditionType!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                                possibleValues: [
                                  "LT",
                                  "BT",
                                  "LTE",
                                  "BTE",
                                  "EQ",
                                  "NEQ",
                                ],
                              },
                              value: {
                                type: "Float!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                            },
                          },
                          propertyCondition: {
                            type: "PropertyCondition!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                            possibleValues: ["EXISTS", "DONT_EXIST"],
                          },
                          errorMessage: {
                            type: "String",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                      memberConditions: {
                        type: "[PlayerCondition!]!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {
                          propertySource: {
                            type: "PlayerConditionDataSource!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                            possibleValues: ["PLAYER", "IDENTITY_PROVIDER"],
                          },
                          propertySourceId: {
                            type: "ID",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                          condition: {
                            type: "Condition!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {
                              property: {
                                type: "String!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              numericCondition: {
                                type: "NumericCondition",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {
                                  conditionType: {
                                    type: "NumberConditionType!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {},
                                  },
                                  value: {
                                    type: "Float!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {},
                                  },
                                },
                              },
                              stringCondition: {
                                type: "StringCondition",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {},
                              },
                              propertyCondition: {
                                type: "PropertyCondition!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                            },
                          },
                          errorMessage: {
                            type: "String",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                          ruleDescription: {
                            type: "String",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                    },
                  },
                  customFields: {
                    type: "[Property!]",
                    description: "Custom fields that are defined on each teams",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                },
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              createdById: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              tournamentSeriesId: {
                type: "ID",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              visibleAt: {
                type: "DateTime",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              teams: {
                type: "TournamentTeams!",
                description: "Teams registered to this tournament",
                required: true,
                isArray: false,
                items: {
                  edges: {
                    type: "[TournamentTeamEdge!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      cursor: {
                        type: "String!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      node: {
                        type: "[TournamentTeam!]!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                    },
                  },
                  nodes: {
                    type: "[TournamentTeam!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  pageInfo: {
                    type: "ResponsePageInfo!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  totalCount: {
                    type: "Float",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                },
              },
              steps: {
                type: "[TournamentStep!]!",
                description: "Steps registered for this tournament",
                required: true,
                isArray: false,
                items: {
                  id: {
                    type: "ID!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  tournamentId: {
                    type: "ID!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  name: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  description: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  order: {
                    type: "Float!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  type: {
                    type: "StepType!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                    possibleValues: [
                      "SCORE",
                      "ROUND_ROBIN",
                      "SINGLE_ELIM",
                      "DOUBLE_ELIM",
                      "CUSTOM",
                    ],
                  },
                  status: {
                    type: "StepStatus!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                    possibleValues: [
                      "CONFIGURED",
                      "GENERATING",
                      "GENERATED",
                      "SEEDING",
                      "SEEDED",
                      "STARTED",
                      "ENDED",
                    ],
                  },
                  configuration: {
                    type: "StepConfiguration!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      type: {
                        type: "ConfigurationType!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      groups: {
                        type: "[GroupConfiguration!]!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {
                          rounds: {
                            type: "[RoundConfiguration!]!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {
                              game: {
                                type: "GameConfiguration!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {
                                  wonMatchCountToWinGame: {
                                    type: "Float!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {},
                                  },
                                  useMatchScoresAsGameScore: {
                                    type: "Boolean!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {},
                                  },
                                  teamsCount: {
                                    type: "Float!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {},
                                  },
                                  matchConfiguration: {
                                    type: "MatchConfiguration!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {
                                      variables: {
                                        type: "[MatchVariable!]!",
                                        description: "",
                                        required: true,
                                        isArray: false,
                                        items: {
                                          formulaName: {
                                            type: "String!",
                                            description: "",
                                            required: true,
                                            isArray: false,
                                            items: {},
                                          },
                                          displayName: {
                                            type: "String!",
                                            description: "",
                                            required: true,
                                            isArray: false,
                                            items: {},
                                          },
                                          displayIcon: {
                                            type: "String",
                                            description: "",
                                            required: false,
                                            isArray: false,
                                            items: {},
                                          },
                                          defaultValue: {
                                            type: "Float!",
                                            description: "",
                                            required: true,
                                            isArray: false,
                                            items: {},
                                          },
                                        },
                                      },
                                      scoreFormula: {
                                        type: "String",
                                        description: "",
                                        required: false,
                                        isArray: false,
                                        items: {},
                                      },
                                    },
                                  },
                                },
                              },
                              order: {
                                type: "Float!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              name: {
                                type: "String!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                            },
                          },
                          name: {
                            type: "String!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                          description: {
                            type: "String",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                    },
                  },
                  createdAt: {
                    type: "DateTime!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  updatedAt: {
                    type: "DateTime!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  teamScores: {
                    type: "[TournamentTeamScore!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  tournament: {
                    type: "Tournament!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
              createdBy: {
                type: "Account!",
                description: "Account that created this tournament",
                required: true,
                isArray: false,
                items: {},
              },
              myTeam: {
                type: "TournamentTeam",
                description:
                  "Current authenticated team registered to this event",
                required: false,
                isArray: false,
                items: {
                  id: {
                    type: "ID!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  name: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  tag: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  teamId: {
                    type: "ID",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  managerId: {
                    type: "ID",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  tournamentId: {
                    type: "ID!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  status: {
                    type: "TournamentTeamStatus!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                    possibleValues: [
                      "NOT_ATTENDING",
                      "NOT_VALID",
                      "REGISTERED",
                      "AWAITING_FOR_PAYMENT",
                      "AWAITING_FOR_PRESENCE_CONFIRMATION",
                      "CONFIRMED",
                      "DENIED",
                    ],
                  },
                  createdAt: {
                    type: "DateTime!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  updatedAt: {
                    type: "DateTime!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  members: {
                    type: "[TournamentTeamMemberDto!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      status: {
                        type: "TournamentTeamMemberStatus!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                        possibleValues: ["AWAITING", "ACCEPTED", "DENIED"],
                      },
                      tournamentTeamId: {
                        type: "ID!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      playerProfileId: {
                        type: "ID!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      createdAt: {
                        type: "DateTime!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      updatedAt: {
                        type: "DateTime!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      team: {
                        type: "TournamentTeam!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                    },
                  },
                  customFields: {
                    type: "[PropertyConfigWithValue!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  tournament: {
                    type: "Tournament!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
              teamScores: {
                type: "[TournamentTeamScore!]!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  teamId: {
                    type: "ID!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  score: {
                    type: "Float!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  team: {
                    type: "TournamentTeam!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
          members: {
            type: "[OrganizationMember!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              organizationId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              groupId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              memberId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              permissions: {
                type: "[GroupPermission!]",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              account: {
                type: "Account!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              group: {
                type: "OrganizationGroup!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          groups: {
            type: "[OrganizationGroup!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              name: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              permissions: {
                type: "[GroupPermission!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              organizationId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              type: {
                type: "OrganizationGroupType!",
                description: "",
                required: true,
                isArray: false,
                items: {},
                possibleValues: ["ANONYMOUS", "CONNECTED", "MEMBER", "ADMIN"],
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          stripeConfigured: {
            type: "Boolean",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          identityProviders: {
            type: "[PublicOrganizationIdentityProvider!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "limitDefinitions",
    description: "",
    scopeOutputs: {
      result: {
        type: "Limits!",
        description: "",
        required: true,
        isArray: false,
        items: {
          limits: {
            type: "[Limit!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              default: {
                type: "Float!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              description: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              name: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              type: {
                type: "OrganizationLimitType!",
                description: "",
                required: true,
                isArray: false,
                items: {},
                possibleValues: ["BOOLEAN", "DAYS", "NUMBER", "BYTES"],
              },
              bucketDuration: {
                type: "BucketDuration",
                description: "",
                required: false,
                isArray: false,
                items: {},
                possibleValues: [
                  "MINUTE",
                  "HOUR",
                  "DAILY",
                  "MONTHLY",
                  "YEARLY",
                ],
              },
              scope: {
                type: "LimitScope!",
                description: "",
                required: true,
                isArray: false,
                items: {},
                possibleValues: ["ORGANIZATION", "RESOURCE"],
              },
              max: {
                type: "Float",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "limits",
        type: "[String!]",
        description: "",
        required: false,
        isArray: true,
        items: {},
      },
    ],
  },
  {
    tag: "organizationLimits",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationLimits!",
        description: "",
        required: true,
        isArray: false,
        items: {
          limits: {
            type: "[OrganizationLimit!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              max: {
                type: "Float!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              current: {
                type: "Float!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "limits",
        type: "[OrganizationLimitsType!]",
        description: "",
        required: false,
        isArray: false,
        items: {},
        possibleValues: [
          "REGISTERED_USERS",
          "ORGANIZATION_GROUPS",
          "ORGANIZATION_MEMBERS",
          "CUSTOM_FIELDS",
          "TOURNAMENT_STEPS",
          "TOURNAMENT_STEP_GROUPS",
          "TOURNAMENT_TEAMS_REGISTERED",
          "TOURNAMENT_TEAM_SIZE",
          "TOURNAMENT_REGISTRATION_RULES",
          "WEBHOOKS",
          "ANONYMOUS_PLAYER_PROFILES",
          "WHITE_LABEL",
          "LEADERBOARDS",
          "LEADERBOARD_ENTRIES",
          "LEADERBOARD_BUCKETS",
          "LEADERBOARD_SEASONS",
          "SKILL_RATINGS",
          "PLATFORMS",
          "ORGANIZATION_STORAGE_SIZE",
          "PLATFORM_CUSTOM_DOMAINS",
          "CUSTOM_IDENTITY_PROVIDERS",
          "APPS",
          "ATTACHED_APPS",
        ],
      },
    ],
  },
  {
    tag: "userOrganizations",
    description: "",
    scopeOutputs: {
      result: {
        type: "[Organization!]!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "organizationGroup",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationGroup!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "organizationGroups",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationGroups!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[OrganizationGroupEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[OrganizationGroup!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[OrganizationGroup!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "availablePermissions",
    description: "",
    scopeOutputs: {
      result: {
        type: "Permissions!",
        description: "",
        required: true,
        isArray: false,
        items: {
          organizationPermissions: {
            type: "[Permission!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              description: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              resources: {
                type: "[String!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              availableVariables: {
                type: "[String!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "organizationMembers",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationMembers!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[OrganizationMemberEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[OrganizationMember!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[OrganizationMember!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "identityProviders",
    description: "",
    scopeOutputs: {
      result: {
        type: "[OrganizationIdentityProvider!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          allowLogin: {
            type: "Boolean!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          icon: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          id: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          identityProviderId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "IdentityProviderConfigurations",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          organizationId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          parentIdentityProvider: {
            type: "PublicIdentityProvider",
            description: "",
            required: false,
            isArray: false,
            items: {
              id: {
                type: "ID!",
                description: "The ID of the identity provider.",
                required: true,
                isArray: false,
                items: {},
              },
              name: {
                type: "String!",
                description: "The name of the identity provider.",
                required: true,
                isArray: false,
                items: {},
              },
              description: {
                type: "String",
                description: "The description of the identity provider.",
                required: false,
                isArray: false,
                items: {},
              },
              icon: {
                type: "String",
                description: "The icon URL of the identity provider.",
                required: false,
                isArray: false,
                items: {},
              },
              availability: {
                type: "IdentityProviderAvailability!",
                description: "The availability of the identity provider.",
                required: true,
                isArray: false,
                items: {},
                possibleValues: ["PUBLIC", "PREVIEW", "PRIVATE"],
              },
              requirements: {
                type: "IdentityProviderRequirements!",
                description: "Identity provider create and update requirements",
                required: true,
                isArray: false,
                items: {
                  requiredFields: {
                    type: "[String!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "identityProvider",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationIdentityProvider!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "organizationApp",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationApp!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          creatorId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          organizationId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          secret: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          public: {
            type: "Boolean",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "OrganizationAppConfig!",
            description: "",
            required: true,
            isArray: false,
            items: {
              name: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              organizationId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              redirectUrls: {
                type: "[String!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              logoutRedirectUrls: {
                type: "[String!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              scope: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              metadata: {
                type: "OrganizationAppConfigMetadata!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  loginUrl: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  consentUrl: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  creatorId: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  requiresConsent: {
                    type: "Boolean!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  public: {
                    type: "Boolean!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
          creator: {
            type: "Account!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "organizationApps",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationApps!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[OrganizationAppEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[OrganizationApp!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[OrganizationApp!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "webhookAvailableEvents",
    description: "",
    scopeOutputs: {
      result: {
        type: "[WebhookEventConfiguration!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          type: {
            type: "WebhookEventType!",
            description: "",
            required: true,
            isArray: false,
            items: {},
            possibleValues: [
              "TOURNAMENT_TEAM_DELETED_ADMIN",
              "TOURNAMENT_TEAM_DELETED",
              "TOURNAMENT_TEAM_STATUS_UPDATED",
              "TOURNAMENT_TEAM_ATTENDANCE_UPDATED",
              "TOURNAMENT_TEAM_CONFIRMATION_UPDATED",
            ],
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          payload: {
            type: "WebhookEventConfigurationProperties!",
            description: "",
            required: true,
            isArray: false,
            items: {
              name: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              description: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              array: {
                type: "Boolean!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              optional: {
                type: "Boolean!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              example: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              type: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              properties: {
                type: "[WebhookEventConfigurationProperties!]",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
            },
          },
          answerPayload: {
            type: "WebhookEventConfigurationProperties",
            description:
              'If this property is available, it means that the event is a "RPC" event and that an answer is expected from the webhook called',
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "webhooks",
    description: "",
    scopeOutputs: {
      result: {
        type: "[Webhook!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          organizationId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          events: {
            type: "[WebhookEventType!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          url: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          active: {
            type: "Boolean!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          secret: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "webhook",
    description: "",
    scopeOutputs: {
      result: {
        type: "Webhook!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "organizationAttachedApps",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationAttachedApps!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[OrganizationAttachedAppEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[OrganizationAttachedApp!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[OrganizationAttachedApp!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              organizationId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              groupId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              appId: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              permissions: {
                type: "[GroupPermission!]",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              app: {
                type: "OrganizationApp!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              group: {
                type: "OrganizationGroup!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "availableRootIdentityProvider",
    description: "",
    scopeOutputs: {
      result: {
        type: "PublicIdentityProvider!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "getAvailableRootIdentityProviders",
    description: "",
    scopeOutputs: {
      result: {
        type: "[PublicIdentityProvider!]!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "searchPlayer",
    description: "",
    scopeOutputs: {
      result: {
        type: "[Player!]!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "username",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "players",
    description: "",
    scopeOutputs: {
      result: {
        type: "Players!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[PlayerEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[Player!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[Player!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "ids",
        type: "[ID!]",
        description: "",
        required: false,
        isArray: true,
        items: {},
      },
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "identityProviders",
        type: "[ID!]!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "identityProviderProperties",
        type: "[String!]!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "query",
        type: "QueryPlayersInput",
        description: "",
        required: false,
        isArray: false,
        items: {
          anonymous: {
            type: "Boolean",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "player",
    description: "",
    scopeOutputs: {
      result: {
        type: "Player!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      {
        name: "ownerId",
        type: "ID",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      {
        name: "customId",
        type: "String",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "playerSkillRatings",
    description: "",
    scopeOutputs: {
      result: {
        type: "[PlayerSkillRating!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          playerProfileId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          ratingId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          value: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          player: {
            type: "Player!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "playerId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "skillRatingIds",
        type: "[ID!]",
        description: "Filter by specific skill rating ids",
        required: false,
        isArray: true,
        items: {},
      },
    ],
  },
  {
    tag: "playerMatchesHistory",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentStepGroupRoundGameMatches!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[TournamentStepGroupRoundGameMatchEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[TournamentStepGroupRoundGameMatch!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[TournamentStepGroupRoundGameMatch!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              order: {
                type: "Float!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              status: {
                type: "MatchStatus!",
                description: "",
                required: true,
                isArray: false,
                items: {},
                possibleValues: ["WAITING", "STARTED", "ENDED"],
              },
              tournamentStepGroupRoundGameId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              configuration: {
                type: "MatchConfiguration!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "playerId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "tournament",
    description: "",
    scopeOutputs: {
      result: {
        type: "Tournament!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "tournaments",
    description: "",
    scopeOutputs: {
      result: {
        type: "Tournaments!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[TournamentEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[Tournament!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[Tournament!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "query",
        type: "TournamentsQuery!",
        description: "",
        required: true,
        isArray: false,
        items: {
          orderBy: {
            type: "TournamentsQueryOrderBy!",
            description: "Order by type",
            required: true,
            isArray: false,
            items: {},
            possibleValues: [
              "START_AT",
              "REGISTRATIONS_START_AT",
              "END_AT",
              "REGISTRATIONS_END_AT",
            ],
          },
          orderDirection: {
            type: "OrderDirection!",
            description: "Order direction",
            required: true,
            isArray: false,
            items: {},
            possibleValues: ["ASC", "DESC"],
          },
          status: {
            type: "TournamentsQueryStatus!",
            description: "Filter by tournament status",
            required: true,
            isArray: false,
            items: {},
            possibleValues: [
              "ALL",
              "STARTED",
              "ENDED",
              "REGISTRATIONS_OPEN",
              "REGISTRATIONS_ENDED",
              "REGISTRATIONS_CLOSED",
            ],
          },
          showNotVisible: {
            type: "Boolean",
            description:
              "Show not visible tournaments, only usable if the user has the correct permission",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "tournamentSteps",
    description: "",
    scopeOutputs: {
      result: {
        type: "[TournamentStep!]!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "tournamentStep",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentStep!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "tournamentStepGeneratedShape",
    description: "",
    scopeOutputs: {
      result: {
        type: "[TournamentStepGroup!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "GroupConfiguration!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          tournamentStepId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          rounds: {
            type: "[TournamentStepGroupRound!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              name: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              configuration: {
                type: "RoundConfiguration!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              tournamentStepGroupId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              order: {
                type: "Float!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              games: {
                type: "[TournamentStepGroupRoundGame!]!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  id: {
                    type: "ID!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  configuration: {
                    type: "GameConfiguration!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  status: {
                    type: "TournamentGameStatus!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                    possibleValues: ["WAITING", "STARTED", "ENDED"],
                  },
                  order: {
                    type: "Float!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  tournamentStepGroupRoundId: {
                    type: "ID!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  winningGameId: {
                    type: "ID",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  losingGameId: {
                    type: "ID",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  createdAt: {
                    type: "DateTime!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  updatedAt: {
                    type: "DateTime!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  matches: {
                    type: "[TournamentStepGroupRoundGameMatch!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "stepId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "match",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentStepGroupRoundGameMatch!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "matchId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "tournamentTeam",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentTeam!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "teamId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "memberStatus",
        type: "TournamentTeamMemberStatus",
        description: "",
        required: false,
        isArray: false,
        items: {},
        possibleValues: ["AWAITING", "ACCEPTED", "DENIED"],
      },
    ],
  },
  {
    tag: "tournamentTeams",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentTeams!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "memberStatus",
        type: "TournamentTeamMemberStatus",
        description: "",
        required: false,
        isArray: false,
        items: {},
        possibleValues: ["AWAITING", "ACCEPTED", "DENIED"],
      },
      {
        name: "status",
        type: "TournamentTeamStatus",
        description: "",
        required: false,
        isArray: false,
        items: {},
        possibleValues: [
          "NOT_ATTENDING",
          "NOT_VALID",
          "REGISTERED",
          "AWAITING_FOR_PAYMENT",
          "AWAITING_FOR_PRESENCE_CONFIRMATION",
          "CONFIRMED",
          "DENIED",
        ],
      },
      {
        name: "propertiesFilter",
        type: "[PropertyValueInput!]",
        description: "",
        required: false,
        isArray: false,
        items: {
          property: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          value: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      {
        name: "playerUsernameQuery",
        type: "String",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      {
        name: "playerPropertiesFilter",
        type: "[PropertyValueInput!]",
        description: "",
        required: false,
        isArray: true,
        items: {},
      },
      {
        name: "playerIdentityProviderPropertiesFilter",
        type: "[IdentityProviderPropertyInput!]",
        description: "",
        required: false,
        isArray: false,
        items: {
          identityProviderId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          property: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          value: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "tournamentPlayers",
    description: "",
    scopeOutputs: {
      result: {
        type: "TeamMembers!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[TournamentTeamMemberEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[TournamentTeamMemberDto!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[TournamentTeamMemberDto!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "status",
        type: "TournamentTeamStatus!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "playerUsernameQuery",
        type: "String",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      {
        name: "playerPropertiesFilter",
        type: "[PropertyValueInput!]",
        description: "",
        required: false,
        isArray: true,
        items: {},
      },
      {
        name: "orderByProperty",
        type: "OrderByProperty",
        description: "",
        required: false,
        isArray: false,
        items: {
          property: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          order: {
            type: "OrderDirection",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "tournamentTeamInvites",
    description: "",
    scopeOutputs: {
      result: {
        type: "TeamMembers!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "tournamentId",
        type: "ID",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      {
        name: "status",
        type: "TournamentTeamMemberStatus",
        description: "",
        required: false,
        isArray: false,
        items: {},
        possibleValues: ["AWAITING", "ACCEPTED", "DENIED"],
      },
    ],
  },
  {
    tag: "tournamentStepGroupRoundGameMatchScoresGetForStep",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentStepGroupRoundGameMatchScores!",
        description: "",
        required: true,
        isArray: false,
        items: {
          edges: {
            type: "[TournamentStepGroupRoundGameMatchScoreEdge!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              cursor: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              node: {
                type: "[TournamentStepGroupRoundGameMatchScore!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          nodes: {
            type: "[TournamentStepGroupRoundGameMatchScore!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              matchId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              teamId: {
                type: "ID!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              score: {
                type: "Float!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              status: {
                type: "MatchScoreStatus!",
                description: "",
                required: true,
                isArray: false,
                items: {},
                possibleValues: ["WAITING", "WINNER", "LOSER", "FORFEIT"],
              },
              createdAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              updatedAt: {
                type: "DateTime!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          pageInfo: {
            type: "ResponsePageInfo!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          totalCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "stepId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "page",
        type: "PageInfo!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "tournamentAdmins",
    description: "",
    scopeOutputs: {
      result: {
        type: "[TournamentAdmin!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          tournamentId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          accountId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          permissions: {
            type: "[TournamentAdminPermissions!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
            possibleValues: [
              "MANAGE_TOURNAMENT",
              "MANAGE_TEAMS",
              "MANAGE_SCORES",
              "MANAGE_PERMISSIONS",
            ],
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          account: {
            type: "Account!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platformFiles",
    description: "",
    scopeOutputs: {
      result: {
        type: "[PlatformCdnFile!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          platformId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          type: {
            type: "PlatformCdnFileType!",
            description: "",
            required: true,
            isArray: false,
            items: {},
            possibleValues: ["PAGE", "COMPONENT", "TEXT", "IMAGE"],
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          config: {
            type: "PlatformCdnFileConfigs",
            description: "",
            required: false,
            isArray: false,
            items: {
              pageConfig: {
                type: "PlatformCdnFilePageConfig",
                description: "",
                required: false,
                isArray: false,
                items: {
                  path: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
          size: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          contents: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "platformId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "type",
        type: "PlatformCdnFileType",
        description: "",
        required: false,
        isArray: false,
        items: {},
        possibleValues: ["PAGE", "COMPONENT", "TEXT", "IMAGE"],
      },
    ],
  },
  {
    tag: "platformFile",
    description: "",
    scopeOutputs: {
      result: {
        type: "PlatformCdnFile!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platforms",
    description: "",
    scopeOutputs: {
      result: {
        type: "[Platform!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "PlatformConfiguration",
            description: "",
            required: false,
            isArray: false,
            items: {
              whitelabel: {
                type: "Boolean",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
            },
          },
          organizationId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          customDomains: {
            type: "[PlatformCustomDomainData!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              domain: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              configuration: {
                type: "CustomDomainData",
                description: "",
                required: false,
                isArray: false,
                items: {
                  hostname: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  ssl: {
                    type: "CustomDomainSsl!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      status: {
                        type: "CustomDomainValidationStatus!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                        possibleValues: [
                          "initializing",
                          "pending_validation",
                          "deleted",
                          "pending_issuance",
                          "pending_deployment",
                          "pending_deletion",
                          "pending_expiration",
                          "expired",
                          "active",
                          "initializing_timed_out",
                          "validation_timed_out",
                          "issuance_timed_out",
                          "deployment_timed_out",
                          "deletion_timed_out",
                          "pending_cleanup",
                          "staging_deployment",
                          "staging_active",
                          "deactivating",
                          "inactive",
                          "backup_issued",
                          "holding_deployment",
                        ],
                      },
                      validationRecords: {
                        type: "[CustomDomainSslValidationRecord!]!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {
                          name: {
                            type: "String!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                          value: {
                            type: "String!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                      validationErrors: {
                        type: "[CustomDomainSslValidationError!]",
                        description: "",
                        required: false,
                        isArray: false,
                        items: {
                          message: {
                            type: "[String!]!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                    },
                  },
                  ownershipVerification: {
                    type: "CustomDomainOwnershipVerification!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      name: {
                        type: "String!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      value: {
                        type: "String!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                    },
                  },
                  status: {
                    type: "CustomDomainStatus!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                    possibleValues: [
                      "active",
                      "pending",
                      "active_redeploying",
                      "moved",
                      "pending_deletion",
                      "deleted",
                      "pending_blocked",
                      "pending_migration",
                      "pending_provisioned",
                      "test_pending",
                      "test_active",
                      "test_active_apex",
                      "test_blocked",
                      "test_failed",
                      "provisioned",
                      "blocked",
                    ],
                  },
                  verificationErrors: {
                    type: "[String!]",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "platform",
    description: "",
    scopeOutputs: {
      result: {
        type: "Platform",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "platformId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platformFindFromDomain",
    description: "",
    scopeOutputs: {
      result: {
        type: "Platform!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "domain",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platformCheckDomainAvailability",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "domain",
        type: "URL!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platformCustomDomains",
    description: "",
    scopeOutputs: {
      result: {
        type: "[PlatformCustomDomainData!]!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "platformId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "subscriptionItems",
    description: "",
    scopeOutputs: {
      result: {
        type: "[SubscriptionItem!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          limitsOverride: {
            type: "[LimitOverride!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              limit: {
                type: "OrganizationLimitsType!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              value: {
                type: "Float!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          maxCount: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          price: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "organizationCurrentSubscriptionItems",
    description: "",
    scopeOutputs: {
      result: {
        type: "[OrganizationSubscriptionItem!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          organizationId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          subscriptionItemId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          count: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          endAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          subscriptionItem: {
            type: "SubscriptionItem!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "organizationSkillRatings",
    description: "",
    scopeOutputs: {
      result: {
        type: "[OrganizationSkillRating!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          id: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          type: {
            type: "SkillRatingType!",
            description: "",
            required: true,
            isArray: false,
            items: {},
            possibleValues: [
              "BREDLEY_TERRY_FULL",
              "BRADLEY_TERRY_PART",
              "THURSTONE_MOSTELLER_FULL",
              "THURSTONE_MOSTELLER_PART",
              "PLACKETT_LUCE",
            ],
          },
          configuration: {
            type: "SkillRatingConfiguration!",
            description: "",
            required: true,
            isArray: false,
            items: {
              updateLeaderboardId: {
                type: "ID",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
            },
          },
          organizationId: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          createdAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          updatedAt: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "organizationSkillRating",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationSkillRating!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "deleteAccount",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "accountIdentityGenerateAccessToken",
    description:
      "Generates access token for an account identity provider with the stored refresh token",
    scopeOutputs: {
      result: {
        type: "String!",
        description:
          "Generates access token for an account identity provider with the stored refresh token",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "identityProviderId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "accountId",
        type: "ID",
        description: "if not provided, the current account id will be used",
        required: false,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "accountIdentityRefresh",
    description: "",
    scopeOutputs: {
      result: {
        type: "AccountIdentity!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "identityProviderId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "accountId",
        type: "ID",
        description: "if not provided, the current account id will be used",
        required: false,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "attachOrUpdateIdentityToAccount",
    description: "",
    scopeOutputs: {
      result: {
        type: "AccountIdentity!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "identityProviderId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "token",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "updateConfiguration",
    description: "",
    scopeOutputs: {
      result: {
        type: "Configuration!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "CreateConfigurationInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "JSONObject!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deleteConfiguration",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "leaderboardRegisterPlayerScore",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeasonBucketScore!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "LeaderboardRegisterPlayerScoreInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          leaderboardId: {
            type: "ID!",
            description: "The ID of the leaderboard to register the score to",
            required: true,
            isArray: false,
            items: {},
          },
          playerId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          score: {
            type: "PositiveInt!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "createLeaderboard",
    description: "",
    scopeOutputs: {
      result: {
        type: "Leaderboard!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "CreateLeaderboardInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "The name of the leaderboard",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "The description of the leaderboard",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "updateLeaderboard",
    description: "",
    scopeOutputs: {
      result: {
        type: "Leaderboard!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "UpdateLeaderboardInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String",
            description: "The name of the leaderboard",
            required: false,
            isArray: false,
            items: {},
          },
          description: {
            type: "String",
            description: "The description of the leaderboard",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deleteLeaderboard",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "createLeaderboardSeason",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeason!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "leaderboardId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "CreateLeaderboardSeasonInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          start: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          end: {
            type: "DateTime!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          startingScore: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "updateLeaderboardSeason",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeason!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "UpdateLeaderboardSeasonInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          start: {
            type: "DateTime",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          end: {
            type: "DateTime",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          startingScore: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deleteLeaderboardSeason",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "createLeaderboardSeasonBucket",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeasonBucket!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "seasonId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "CreateLeaderboardSeasonBucketInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          minScore: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          scoreCalculationType: {
            type: "LeaderboardScoreCalculationType!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "updateLeaderboardSeasonBucket",
    description: "",
    scopeOutputs: {
      result: {
        type: "LeaderboardSeasonBucket!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "UpdateLeaderboardSeasonBucketInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          minScore: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          scoreCalculationType: {
            type: "LeaderboardScoreCalculationType",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deleteLeaderboardSeasonBucket",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "updateOrganization",
    description: "",
    scopeOutputs: {
      result: {
        type: "Organization!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "organization",
        type: "UpdateOrganizationInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "OrganizationConfigurationInput!",
            description: "",
            required: true,
            isArray: false,
            items: {
              customFields: {
                type: "[PropertyInput!]!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  property: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  name: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  type: {
                    type: "PropertyType!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  required: {
                    type: "Boolean!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  order: {
                    type: "Float!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  unique: {
                    type: "Boolean!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  public: {
                    type: "Boolean",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  visibility: {
                    type: "PropertyVisibility",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  editability: {
                    type: "ProperyEditability",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    tag: "createOrganization",
    description: "",
    scopeOutputs: {
      result: {
        type: "Organization!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "organization",
        type: "CreateOrganizationInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "OrganizationConfigurationInput!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "createOrganizationGroup",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationGroup!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "CreateOrganizationGroupInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          permissions: {
            type: "[GroupPermissionInput!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              id: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              resources: {
                type: "[String!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
    ],
  },
  {
    tag: "updateOrganizationGroup",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationGroup!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "CreateOrganizationGroupInput!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "deleteOrganizationGroup",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "setOrganizationMembership",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationMember!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "SetOrganizationMembershipInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          userId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          email: {
            type: "EmailAddress",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          permissions: {
            type: "[GroupPermissionInput!]",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          groupId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deleteOrganizationMembership",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "DeleteOrganizationMembershipInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          userId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "createIdentityProvider",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationIdentityProvider!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "CreateOrganizationIdentityProvider!",
        description: "",
        required: true,
        isArray: false,
        items: {
          allowLogin: {
            type: "Boolean!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          icon: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          identityProviderId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          oauth2Configuration: {
            type: "OAuthClientConfigurationInput",
            description: "",
            required: false,
            isArray: false,
            items: {
              authorizationEndpoint: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              dataRetrievers: {
                type: "[DataRetrievalConfigInput!]!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  url: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  headers: {
                    type: "[HeaderInput!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      name: {
                        type: "String!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      value: {
                        type: "String!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                    },
                  },
                  mappingConfiguration: {
                    type: "MappingConfigurationInput!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      mappings: {
                        type: "[KeyMappingInput!]!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {
                          path: {
                            type: "String!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                          mappedTo: {
                            type: "String!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                          private: {
                            type: "Boolean",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                    },
                  },
                },
              },
              issuer: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              providerType: {
                type: "IdentityProviderType!",
                description: "",
                required: true,
                isArray: false,
                items: {},
                possibleValues: ["OPENID", "OAUTH2"],
              },
              clientId: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              clientSecret: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              tokenEndpointAuthMethod: {
                type: "OauthEndpointAuthMethod",
                description: "",
                required: false,
                isArray: false,
                items: {},
                possibleValues: [
                  "CLIENT_SECRET_POST",
                  "CLIENT_SECRET_BASIC",
                  "CLIENT_SECRET_JWT",
                  "PRIVATE_KEY_JWT",
                  "TLS_CLIENT_AUTH",
                  "SELF_SIGNED_TLS_CLIENT_AUTH",
                  "NONE",
                ],
              },
              redirectUrl: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              tokenEndpoint: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              authorizationUrl: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
            },
          },
          openidConfiguration: {
            type: "OpenidConfigurationInput",
            description: "",
            required: false,
            isArray: false,
            items: {
              authorizationEndpoint: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              dataRetrievers: {
                type: "[DataRetrievalConfigInput!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              issuer: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              providerType: {
                type: "IdentityProviderType!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              clientId: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              clientSecret: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              redirectUrl: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
    ],
  },
  {
    tag: "updateIdentityProvider",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationIdentityProvider!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "providerId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "UpdateOrganizationIdentityProvider!",
        description: "",
        required: true,
        isArray: false,
        items: {
          allowLogin: {
            type: "Boolean!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          icon: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          oauth2Configuration: {
            type: "OAuthClientConfigurationInput",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          openidConfiguration: {
            type: "OpenidConfigurationInput",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deleteIdentityProvider",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "createOrganizationApp",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationApp!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "CreateOrganizationAppInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          secret: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          public: {
            type: "Boolean",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          redirectUrls: {
            type: "[String!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          logoutRedirectUrls: {
            type: "[String!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          loginUrl: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          consentUrl: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          requiresConsent: {
            type: "Boolean!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "resetOrganizationAppSecret",
    description: "",
    scopeOutputs: {
      result: {
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "updateOrganizationApp",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationApp!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "UpdateOrganizationAppInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          description: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          secret: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          redirectUrls: {
            type: "[String!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          logoutRedirectUrls: {
            type: "[String!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          loginUrl: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          consentUrl: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          requiresConsent: {
            type: "Boolean!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deleteOrganizationApp",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "createWebhook",
    description: "",
    scopeOutputs: {
      result: {
        type: "Webhook!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "CreateWebhookInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          events: {
            type: "[WebhookEventType!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          url: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          active: {
            type: "Boolean!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "updateWebhook",
    description: "",
    scopeOutputs: {
      result: {
        type: "Webhook!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "UpdateWebhookInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          events: {
            type: "[WebhookEventType!]",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          url: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          description: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          active: {
            type: "Boolean",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deleteWebhook",
    description: "",
    scopeOutputs: {
      result: {
        type: "Webhook!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "regenerateWebhookSecret",
    description: "",
    scopeOutputs: {
      result: {
        type: "Webhook!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "setOrganizationAttachedApp",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationAttachedApp!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "SetOrganizationAttachedAppInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          appId: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          permissions: {
            type: "[GroupPermissionInput!]",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          groupId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deleteOrganizationAttachedApp",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "DeleteOrganizationAttachedAppInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          appId: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "createPlayer",
    description: "",
    scopeOutputs: {
      result: {
        type: "Player!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "CreatePlayerInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          username: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          profilePicUrl: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          ownerId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          organizationCustomId: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          customFields: {
            type: "[PlayerCustomFieldInput!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              property: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              value: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
    ],
  },
  {
    tag: "updatePlayer",
    description: "",
    scopeOutputs: {
      result: {
        type: "Player!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "UpdatePlayerInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          username: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          profilePicUrl: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          description: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          ownerId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          organizationCustomId: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          customFields: {
            type: "[PlayerCustomFieldInput!]",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      {
        name: "id",
        type: "ID",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      {
        name: "customId",
        type: "String",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "createOrUpdateMyPlayerProfile",
    description: "",
    scopeOutputs: {
      result: {
        type: "Player!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "UpdateMePlayerInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          username: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          profilePicUrl: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          customFields: {
            type: "[PlayerCustomFieldInput!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "deletePlayer",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      {
        name: "customId",
        type: "ID",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "createTournament",
    description: "Create a new tournament",
    scopeOutputs: {
      result: {
        type: "Tournament!",
        description: "Create a new tournament",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "CreateTournamentInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          title: {
            type: "String!",
            description: "Tournament title",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "Tournament description",
            required: true,
            isArray: false,
            items: {},
          },
          startAt: {
            type: "DateTime",
            description: "Tournament start date",
            required: false,
            isArray: false,
            items: {},
          },
          endAt: {
            type: "DateTime",
            description: "Tournament end date",
            required: false,
            isArray: false,
            items: {},
          },
          startRegistrationsAt: {
            type: "DateTime",
            description: "Tournament registrations start date",
            required: false,
            isArray: false,
            items: {},
          },
          endRegistrationsAt: {
            type: "DateTime",
            description: "Tournament registrations end date",
            required: false,
            isArray: false,
            items: {},
          },
          visibleAt: {
            type: "DateTime",
            description:
              "Tournament visibility date, the tournament will be visible from this date in the tournaments list",
            required: false,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "UpdateTournamentConfigurationOrImportFromIdInput",
            description: "Tournament configuration",
            required: false,
            isArray: false,
            items: {
              fromId: {
                type: "ID",
                description: "Import configuration from a saved configuration",
                required: false,
                isArray: false,
                items: {},
              },
              configuration: {
                type: "UpdateTournamentConfigurationInput",
                description: "Tournament configuration",
                required: false,
                isArray: false,
                items: {
                  type: {
                    type: "ConfigurationType",
                    description: "",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  teamMinSize: {
                    type: "Float",
                    description:
                      "Minimum team size to register to the tournament",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  teamMaxSize: {
                    type: "Float",
                    description:
                      "Maximum team size to register to the tournament",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  teamsCount: {
                    type: "Float",
                    description: "Maximum number of registered teams",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  teamStatusAfterRegistration: {
                    type: "TeamStatusAfterRegistration",
                    description:
                      "Status of the team after registration, REGISTERED is the default state, VALIDATED means that the team has been validated by an admin, AWAITING_FOR_PAYMENT means that the team has to pay to be validated",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  registrationConditions: {
                    type: "RegistrationConditionsInput",
                    description:
                      "Condition rules to register to the tournament",
                    required: false,
                    isArray: false,
                    items: {
                      teamConditions: {
                        type: "[TeamConditionInput!]!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {
                          property: {
                            type: "String!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                          stringCondition: {
                            type: "StringConditionInput",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {
                              conditionType: {
                                type: "StringConditionType!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              value: {
                                type: "String!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                            },
                          },
                          numericCondition: {
                            type: "TeamNumericConditionInput",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {
                              aggregationType: {
                                type: "AggregationType",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {},
                              },
                              propertySource: {
                                type: "PlayerConditionDataSource",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {},
                              },
                              propertySourceId: {
                                type: "ID",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {},
                              },
                              conditionType: {
                                type: "NumberConditionType!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              value: {
                                type: "Float!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                            },
                          },
                          propertyCondition: {
                            type: "PropertyCondition!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                          errorMessage: {
                            type: "String",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                      memberConditions: {
                        type: "[PlayerConditionInput!]!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {
                          propertySource: {
                            type: "PlayerConditionDataSource!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {},
                          },
                          propertySourceId: {
                            type: "ID",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                          condition: {
                            type: "ConditionInput!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {
                              property: {
                                type: "String!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              numericCondition: {
                                type: "NumberConditionInput",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {
                                  conditionType: {
                                    type: "NumberConditionType!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {},
                                  },
                                  value: {
                                    type: "Float!",
                                    description: "",
                                    required: true,
                                    isArray: false,
                                    items: {},
                                  },
                                },
                              },
                              stringCondition: {
                                type: "StringConditionInput",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {},
                              },
                              propertyCondition: {
                                type: "PropertyCondition!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                            },
                          },
                          errorMessage: {
                            type: "String",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                          ruleDescription: {
                            type: "String",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                    },
                  },
                  customFields: {
                    type: "[PropertyInput!]",
                    description: "Custom fields that are defined on each teams",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    tag: "updateTournament",
    description: "Update a tournament",
    scopeOutputs: {
      result: {
        type: "Tournament!",
        description: "Update a tournament",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "UpdateTournamentInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          title: {
            type: "String",
            description: "Tournament title",
            required: false,
            isArray: false,
            items: {},
          },
          description: {
            type: "String",
            description: "Tournament description",
            required: false,
            isArray: false,
            items: {},
          },
          startAt: {
            type: "DateTime",
            description: "Tournament start date",
            required: false,
            isArray: false,
            items: {},
          },
          endAt: {
            type: "DateTime",
            description: "Tournament end date",
            required: false,
            isArray: false,
            items: {},
          },
          startRegistrationsAt: {
            type: "DateTime",
            description: "Tournament registrations start date",
            required: false,
            isArray: false,
            items: {},
          },
          endRegistrationsAt: {
            type: "DateTime",
            description: "Tournament registrations end date",
            required: false,
            isArray: false,
            items: {},
          },
          visibleAt: {
            type: "DateTime",
            description:
              "Tournament visibility date, the tournament will be visible from this date in the tournaments list",
            required: false,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "UpdateTournamentConfigurationOrImportFromIdInput",
            description: "Tournament configuration",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "deleteTournament",
    description: "Delete a tournament",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "Delete a tournament",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "startTournamentStep",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "stepId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "createTournamentStep",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentStep!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "configuration",
        type: "StepConfigurationInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          type: {
            type: "ConfigurationType!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          groups: {
            type: "[GroupConfigurationInput!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              rounds: {
                type: "[RoundConfigurationInput!]!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  game: {
                    type: "GameConfigurationInput!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {
                      wonMatchCountToWinGame: {
                        type: "Float!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      useMatchScoresAsGameScore: {
                        type: "Boolean!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      teamsCount: {
                        type: "Float!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {},
                      },
                      matchConfiguration: {
                        type: "MatchConfigurationInput!",
                        description: "",
                        required: true,
                        isArray: false,
                        items: {
                          variables: {
                            type: "[MatchVariableInput!]!",
                            description: "",
                            required: true,
                            isArray: false,
                            items: {
                              formulaName: {
                                type: "String!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              displayName: {
                                type: "String!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                              displayIcon: {
                                type: "String",
                                description: "",
                                required: false,
                                isArray: false,
                                items: {},
                              },
                              defaultValue: {
                                type: "Float!",
                                description: "",
                                required: true,
                                isArray: false,
                                items: {},
                              },
                            },
                          },
                          scoreFormula: {
                            type: "String",
                            description: "",
                            required: false,
                            isArray: false,
                            items: {},
                          },
                        },
                      },
                    },
                  },
                  order: {
                    type: "Float!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  name: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
              name: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              description: {
                type: "String",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
      {
        name: "step",
        type: "CreateTournamentStepInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          order: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          type: {
            type: "StepType!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          properties: {
            type: "[PropertyValueInput!]",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "updateTournamentStep",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentStep!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "stepId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "configuration",
        type: "StepConfigurationInput!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "step",
        type: "CreateTournamentStepInput!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "deleteTournamentStep",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "stepId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "generateTournamentStep",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "stepId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "teamsCount",
        type: "Int!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "seedTournamentStep",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "stepId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "SeedingInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          automaticSeeding: {
            type: "AutomaticSeedingInput",
            description: "",
            required: false,
            isArray: false,
            items: {
              seedingMechanism: {
                type: "SeedingMechanism!",
                description: "",
                required: true,
                isArray: false,
                items: {},
                possibleValues: [
                  "NONE",
                  "REVERSE",
                  "HALF_SHIFT",
                  "REVERSE_HALF_SHIFT",
                  "PAIR_FLIP",
                ],
              },
              groupRepartitionMechanism: {
                type: "GroupRepartitionMechanism!",
                description: "",
                required: true,
                isArray: false,
                items: {},
                possibleValues: ["BALANCED", "SEED_OPTIMIZED"],
              },
              teams: {
                type: "[ID!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          manualSeeding: {
            type: "ManualSeedingInput",
            description: "",
            required: false,
            isArray: false,
            items: {
              groups: {
                type: "[ManualSeedingGroupInput!]!",
                description: "",
                required: true,
                isArray: false,
                items: {
                  group: {
                    type: "ID!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                  teams: {
                    type: "[ID!]!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    tag: "updateMatchScores",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentStepGroupRoundGameMatch!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "matchId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "[UpdateMatchScoreInput!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          teamId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          variables: {
            type: "[UpdateTournamentMatchScoreVariablesInput!]",
            description: "",
            required: false,
            isArray: false,
            items: {
              formulaName: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              value: {
                type: "Float!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
          forcedScoreValue: {
            type: "Float",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          status: {
            type: "MatchScoreStatus",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "registerCustomTournamentTeams",
    description: "",
    scopeOutputs: {
      result: {
        type: "[TournamentTeam!]!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "RegisterCustomTeamsInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          customTeams: {
            type: "[RegisterCustomTeamInput!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              name: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              tag: {
                type: "String!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
              customFields: {
                type: "[PropertyValueInput!]",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              fromExistingTeamId: {
                type: "ID",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
              members: {
                type: "[CustomTeamMemberInput!]",
                description: "",
                required: false,
                isArray: false,
                items: {
                  playerId: {
                    type: "ID",
                    description:
                      "An existing player profile ID to use for this team, you have to set either playerId or player, not both",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                  player: {
                    type: "CreatePlayerInput",
                    description:
                      "A custom created player, will create a real player profile if set. You should set either player or playerId, not both",
                    required: false,
                    isArray: false,
                    items: {},
                  },
                },
              },
              status: {
                type: "TournamentTeamStatus!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
    ],
  },
  {
    tag: "registerTournamentTeam",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentTeam!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "RegisterTournamentTeamInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          tag: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          customFields: {
            type: "[PropertyValueInput!]",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          fromExistingTeamId: {
            type: "ID",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "updateTournamentTeam",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentTeam!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "UpdateTournamentTeamInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          tag: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          customFields: {
            type: "[PropertyValueInput!]",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
      {
        name: "tournamentId",
        type: "ID",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
      {
        name: "teamId",
        type: "ID",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "deleteTournamentTeam",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "deleteAnyTournamentTeam",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "teamId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "confirmTournamentTeam",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentTeam!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "teamId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "confirm",
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "confirmTournamentAttendance",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentTeam!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "attendance",
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "updateTournamentTeamStatus",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentTeam!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentTeamId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "status",
        type: "TournamentTeamStatus!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "inviteTournamentTeamMember",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "tournamentId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "playerId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "answerToTournamentTeamInvite",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "teamId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "accept",
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "deleteTournamentTeamInvite",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "teamId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "playerId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "tournamentAdminUpsert",
    description: "",
    scopeOutputs: {
      result: {
        type: "TournamentAdmin!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "TournamentAdminUpsertInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          tournamentId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          accountId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          permissions: {
            type: "[TournamentAdminPermissions!]!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "tournamentAdminDelete",
    description: "",
    scopeOutputs: {
      result: {
        type: "Boolean!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "TournamentAdminDeleteInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          tournamentId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          accountId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "stripeCreatePortalSession",
    description: "",
    scopeOutputs: {
      result: {
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [],
  },
  {
    tag: "platformFileCreate",
    description: "returns the s3 signed upload URL",
    scopeOutputs: {
      result: {
        type: "PlatformCdnFile!",
        description: "returns the s3 signed upload URL",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "platformId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "PlatformCdnFileCreateInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          type: {
            type: "PlatformCdnFileType!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          config: {
            type: "PlatformCdnFileConfigsInput",
            description: "",
            required: false,
            isArray: false,
            items: {
              pageConfig: {
                type: "PlatformCdnFilePageConfigInput",
                description: "",
                required: false,
                isArray: false,
                items: {
                  path: {
                    type: "String!",
                    description: "",
                    required: true,
                    isArray: false,
                    items: {},
                  },
                },
              },
            },
          },
          contents: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "platformFilePublish",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platformFileUpdate",
    description: "",
    scopeOutputs: {
      result: {
        type: "PlatformCdnFile!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "PlatformFileUpdateInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          config: {
            type: "PlatformCdnFileConfigsInput",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          contents: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "platformFileDelete",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platformCreate",
    description: "",
    scopeOutputs: {
      result: {
        type: "Platform!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "CreatePlatformInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "PlatformConfigurationInput",
            description: "",
            required: false,
            isArray: false,
            items: {
              whitelabel: {
                type: "Boolean",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
            },
          },
          subdomain: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "platformUpdate",
    description: "",
    scopeOutputs: {
      result: {
        type: "Platform!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "UpdatePlatformInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "PlatformConfigurationInput",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "platformDelete",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platformRegisterCustomDomain",
    description: "",
    scopeOutputs: {
      result: {
        type: "CustomDomainData!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "platformId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "hostname",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platformForceCustomDomainRecheck",
    description: "",
    scopeOutputs: {
      result: {
        type: "CustomDomainData!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "hostname",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "platformRemoveCustomDomain",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "hostname",
        type: "String!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "organizationSubscriptionItemsUpdate",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "items",
        type: "[OrganizationSubscriptionItemUpdateInput!]!",
        description: "",
        required: true,
        isArray: false,
        items: {
          subscriptionItemId: {
            type: "ID!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          count: {
            type: "Float!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
        },
      },
      {
        name: "coupon",
        type: "String",
        description: "",
        required: false,
        isArray: false,
        items: {},
      },
    ],
  },
  {
    tag: "organizationSkillRatingsRegisterMatch",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "organizationSkillRatingId",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "OrganizationSkillRatingsRegisterMatchInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          teams: {
            type: "[OrganizationSkillRatingsRegisterMatchTeamInput!]!",
            description: "",
            required: true,
            isArray: false,
            items: {
              players: {
                type: "[ID!]!",
                description: "",
                required: true,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
    ],
  },
  {
    tag: "organizationSkillRatingCreate",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationSkillRating!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "input",
        type: "OrganizationSkillRatingCreateInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          description: {
            type: "String!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          type: {
            type: "SkillRatingType!",
            description: "",
            required: true,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "SkillRatingConfigurationInput!",
            description: "",
            required: true,
            isArray: false,
            items: {
              updateLeaderboardId: {
                type: "ID",
                description: "",
                required: false,
                isArray: false,
                items: {},
              },
            },
          },
        },
      },
    ],
  },
  {
    tag: "organizationSkillRatingUpdate",
    description: "",
    scopeOutputs: {
      result: {
        type: "OrganizationSkillRating!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      {
        name: "input",
        type: "OrganizationSkillRatingUpdateInput!",
        description: "",
        required: true,
        isArray: false,
        items: {
          name: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          description: {
            type: "String",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          type: {
            type: "SkillRatingType",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
          configuration: {
            type: "SkillRatingConfigurationInput",
            description: "",
            required: false,
            isArray: false,
            items: {},
          },
        },
      },
    ],
  },
  {
    tag: "organizationSkillRatingDelete",
    description: "",
    scopeOutputs: {
      result: {
        type: "Void!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
      error: {
        type: "String",
        description: "Error message",
        required: false,
      },
    },
    hashInputs: [
      {
        name: "id",
        type: "ID!",
        description: "",
        required: true,
        isArray: false,
        items: {},
      },
    ],
  },
];
