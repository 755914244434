import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createWSClient } from "./ws";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { constructWithoutUndefined } from "./utils/object";
import { selectedOrganizationIdSubject } from "./business/OrganizationId";
import { notifications } from "@mantine/notifications";
import { GQL_URL, IS_LOCAL } from "./server-select";

const link = split(
  ({ query }) => {
    const def = getMainDefinition(query);
    return (
      def.kind === "OperationDefinition" && def.operation === "subscription"
    );
  },
  new GraphQLWsLink(createWSClient(GQL_URL)),
  from([
    setContext(async (operation, prevContext) => {
      let token: string | undefined;
      for (let i = 0; i < sessionStorage.length; ++i) {
        const key = sessionStorage.key(i);
        if (key && key.startsWith("oidc.wp")) {
          const value = sessionStorage.getItem(key);
          if (value) {
            token = JSON.parse(value).tokens?.accessToken;
            break;
          }
        }
      }

      return {
        headers: constructWithoutUndefined({
          "organization-id":
            selectedOrganizationIdSubject.getValue() ?? undefined,
          authorization: token ? `Bearer ${token}` : undefined,
        }),
      };
    }),
    onError((gqlErr) => {
      if (gqlErr.networkError) {
        console.error(gqlErr);

        notifications.show({
          title: "Network Error",
          message: `${gqlErr.networkError.message}, please try again later`,
          variant: "error",
          color: "red",
        });
      }

      gqlErr.graphQLErrors?.forEach((error) => {
        if (error.message === "No authorization token found") return;

        console.error(error);

        notifications.show({
          title: "Error",
          message: error.message,
          variant: "error",
          color: "red",
        });
      });

      return gqlErr.forward(gqlErr.operation);
    }),
    new HttpLink({
      uri: `http${IS_LOCAL ? "" : "s"}://` + GQL_URL,
    }),
  ]),
);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
