import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import {
  TournamentHome,
  Tournaments,
  UpdateTournament,
  UpdateTournamentCustomFields,
  UpdateTournamentRegistrationRules,
  UpdateTournamentRegistrations,
} from "./services/tournament/Tournaments";
import {
  CreateMember,
  Members,
} from "./services/organization/iam/member/Members";
import { Groups, UpdateGroup } from "./services/organization/iam/group/Groups";
import { Apps, AppsHome, EditApp } from "./services/organization/app/Apps";
import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc";
import { useEffect } from "react";
import { PageNotFound } from "./screens/PageNotFound";
import { AppShell, Center, Image, Loader, Stack } from "@mantine/core";
import { HeaderMegaMenu } from "./components/app-shell/header/Header";
import {
  IconApps,
  IconArrowBack,
  IconBook,
  IconBrandPagekit,
  IconCalendarEvent,
  IconChartBar,
  IconCreditCard,
  IconDashboard,
  IconFileText,
  IconGauge,
  IconGlobe,
  IconHome,
  IconMedal,
  IconPaint,
  IconPlanet,
  IconSettings,
  IconShoppingBag,
  IconStar,
  IconSword,
  IconTrophy,
  IconUserBolt,
  IconUserCircle,
  IconUsers,
  IconUsersGroup,
  IconUserStar,
  IconWebhook,
} from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { ServiceWithMenuLayer } from "./components/app-shell/ServiceWithMenuLayer";
import {
  CreateOrganization,
  OrganizationsHome,
  OrganizationsList,
} from "./services/organization/Organizations";
import { OrganizationConfiguration } from "./services/organization/OrganizationConfiguration";
import { PlayersHome, PlayersList } from "./services/player-profile/Players";
import {
  IdentityProviders,
  UpdateIdentityProvider,
} from "./services/player-profile/identityProvider/IdentityProviders";
import { TournamentTeams } from "./services/tournament/TournamentTeams";
import {
  TournamentStepHome,
  TournamentSteps,
  UpdateTournamentStep,
} from "./services/tournament/TournamentSteps";
import { Limits, LimitsHome } from "./services/limits/Limits";
import { Dashboard } from "./dashboard/Dashboard";
import {
  CurrentLeaderboard,
  Leaderboards,
  LeaderboardsHome,
  UpdateLeaderboard,
} from "./services/leaderboard/Leaderboards";
import { TournamentDashboard } from "./services/tournament/TournamentDashboard";
import { LeaderboardDashboard } from "./services/leaderboard/LeaderboardDashboard";
import { LeaderboardSeasons } from "./services/leaderboard/LeaderboardSeasons";
import { IAMHome } from "./services/organization/iam/IAM";
import { LeaderboardSeasonBuckets } from "./services/leaderboard/LeaderboardSeasonBuckets";
import { LeaderboardWidgets } from "./services/leaderboard/LeaderboardWidgets";
import { PlayerView } from "./services/player-profile/Player";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  CreateOrUpdatePlatform,
  Platforms,
} from "./services/platform/Platforms";
import { PlatformHome } from "./services/platform/PlatformHome";
import {
  Platform,
  PlatformDomains,
  RegisterPlatformDomain,
} from "./services/platform/Platform";
import {
  PlatformFiles,
  PlatformFileUpdate,
} from "./services/platform/files/PlatformFiles";
import { PlatformFileContent } from "./services/platform/files/PlatformFile";
import { Subscription } from "./services/limits/subscription/Subscription";
import { AppsDocumentation } from "./services/organization/app/AppsDocumentation";
import { IAMDocumentation } from "./services/organization/iam/IAMDocumentation";
import {
  CreateOrUpdateSkillRating,
  SkillRatings,
} from "./services/player-profile/skill-rating/SkillRatings";
import { PlayerDocumentation } from "./services/player-profile/PlayerDocumentation";
import { ServiceLinks } from "./ServiceLinks";
import { LeaderboardDocumentation } from "./services/leaderboard/Documentations";
import {
  AttachApplication,
  AttachedApplications,
} from "./services/organization/iam/applications/Applications";
import { useCurrentAccount } from "../../business/CurrentAccount";
import { TournamentAdmins } from "./services/tournament/TournamentAdmins";
import { WebhookLogs } from "./services/organization/app/webhooks/WebhookLogs";
import { WebhookHome } from "./services/organization/app/webhooks/WebhookHome";
import { WebhookUpdate } from "./services/organization/app/webhooks/WebhookUpdate";
import { Webhooks } from "./services/organization/app/Webhooks";
import { IS_LOCAL, IS_STG } from "../../server-select";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: "eu-west-3_05Tcmw8bn",
      userPoolClientId:
        IS_LOCAL || IS_STG
          ? "20h9k2luca7ibf9sq6moh30mop"
          : "1ics32krcep4s9cafqdbursvhp",
    },
  },
});

const LayoutWithSidebar = () => {
  const { account, loading } = useCurrentAccount();

  useEffect(() => {
    if (account?.getMyAccount.email) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      $crisp.push(["set", "user:email", [account.getMyAccount.email]]);
    }
  }, [account]);

  if (loading) {
    return <Loader />;
  }

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={<HeaderMegaMenu links={ServiceLinks} />}
    >
      <Outlet />
    </AppShell>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <OidcSecure configurationName="wp">
        <LayoutWithSidebar />
      </OidcSecure>
    ),
    errorElement: <PageNotFound />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/organizations",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Organizations",
              items: [
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/organizations",
                },
                {
                  icon: IconUserCircle,
                  label: "Organizations",
                  path: "/organizations/list",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <OrganizationsHome />,
          },
          {
            path: "list",
            element: <OrganizationsList />,
          },
          {
            path: "new",
            element: <CreateOrganization />,
          },
          {
            path: ":organizationId",
            element: <OrganizationConfiguration />,
          },
        ],
      },
      {
        path: "/apps",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Apps",
              items: [
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/apps",
                },
                {
                  icon: IconApps,
                  label: "Apps",
                  path: "/apps/list",
                },
                {
                  icon: IconWebhook,
                  label: "Webhooks",
                  path: "/apps/webhooks",
                },
                {
                  icon: IconBook,
                  label: "Documentations",
                  path: "/apps/documentations",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <AppsHome />,
          },
          {
            path: "list",
            element: <Apps />,
          },
          {
            path: "webhooks",
            element: <Webhooks />,
          },
          {
            path: "webhooks/new",
            element: <WebhookUpdate />,
          },
          {
            path: "new",
            element: <EditApp />,
          },
          {
            path: ":appId",
            element: <EditApp />,
          },
          {
            path: "documentations",
            element: <AppsDocumentation />,
          },
        ],
      },
      {
        path: "/apps/webhooks/:webhookId",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Webhook",
              items: [
                {
                  icon: IconArrowBack,
                  label: "Back",
                  path: "/apps/webhooks",
                },
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/apps/webhooks/:webhookId",
                },
                {
                  icon: IconSettings,
                  label: "Configuration",
                  path: "/apps/webhooks/:webhookId/configuration",
                },
                {
                  icon: IconFileText,
                  label: "Logs",
                  path: "/apps/webhooks/:webhookId/logs",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <WebhookHome />,
          },
          {
            path: "configuration",
            element: <WebhookUpdate />,
          },
          {
            path: "logs",
            element: <WebhookLogs />,
          },
        ],
      },
      {
        path: "/iam",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "IAM",
              items: [
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/iam",
                },
                {
                  icon: IconUsersGroup,
                  label: "Groups",
                  path: "/iam/groups",
                },
                {
                  icon: IconUsers,
                  label: "Members",
                  path: "/iam/members",
                },
                {
                  icon: IconApps,
                  label: "Applications",
                  path: "/iam/applications",
                },
                {
                  icon: IconBook,
                  label: "Documentations",
                  path: "/iam/documentations",
                  disabled: true,
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <IAMHome />,
          },
          {
            path: "groups",
            element: <Groups />,
          },
          {
            path: "groups/new",
            element: <UpdateGroup />,
          },
          {
            path: "groups/:groupId",
            element: <UpdateGroup />,
          },
          {
            path: "members",
            element: <Members />,
          },
          {
            path: "members/new",
            element: <CreateMember />,
          },
          {
            path: "members/:memberId",
            element: <CreateMember />,
          },
          {
            path: "applications",
            element: <AttachedApplications />,
          },
          {
            path: "applications/new",
            element: <AttachApplication />,
          },
          {
            path: "documentations",
            element: <IAMDocumentation />,
          },
        ],
      },
      {
        path: "/tournaments",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Tournaments",
              items: [
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/tournaments",
                },
                {
                  icon: IconUsersGroup,
                  label: "Tournaments",
                  path: "/tournaments/list",
                },
                {
                  icon: IconUsers,
                  label: "Series",
                  path: "/tournaments/series",
                  disabled: true,
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <TournamentHome />,
          },
          {
            path: "list",
            element: <Tournaments />,
          },
          {
            path: "series",
            element: <>Not available yet</>,
          },
          {
            path: "new",
            element: <UpdateTournament />,
          },
        ],
      },
      {
        path: "/tournaments/:tournamentId",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Tournament",
              items: [
                {
                  icon: IconArrowBack,
                  label: "Back",
                  path: "/tournaments/list",
                },
                {
                  icon: IconDashboard,
                  label: "Dashboard",
                  path: "/tournaments/:tournamentId",
                },
                {
                  icon: IconUsersGroup,
                  label: "Teams",
                  path: "/tournaments/:tournamentId/teams",
                },
                {
                  icon: IconSettings,
                  label: "Settings",
                  children: [
                    {
                      label: "General",
                      path: "/tournaments/:tournamentId/settings/general",
                    },
                    {
                      label: "Registration",
                      path: "/tournaments/:tournamentId/settings/registration",
                    },
                    {
                      label: "Registration Rules",
                      path: "/tournaments/:tournamentId/settings/registration-rules",
                    },
                    {
                      label: "Custom Fields",
                      path: "/tournaments/:tournamentId/settings/custom-fields",
                    },
                  ],
                },
                {
                  icon: IconChartBar,
                  label: "Steps",
                  path: "/tournaments/:tournamentId/steps",
                },
                {
                  icon: IconUserStar,
                  label: "Admins",
                  path: "/tournaments/:tournamentId/admins",
                },
                {
                  icon: IconApps,
                  label: "Widgets",
                  path: "/tournaments/:tournamentId/widgets",
                  disabled: true,
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <TournamentDashboard />,
          },
          {
            path: "general",
            element: <UpdateTournament />,
          },
          {
            path: "settings/general",
            element: <UpdateTournament />,
          },
          {
            path: "settings/registration",
            element: <UpdateTournamentRegistrations />,
          },
          {
            path: "settings/registration-rules",
            element: <UpdateTournamentRegistrationRules />,
          },
          {
            path: "settings/custom-fields",
            element: <UpdateTournamentCustomFields />,
          },
          {
            path: "teams",
            element: <TournamentTeams />,
          },
          {
            path: "steps",
            element: <TournamentSteps />,
          },
          {
            path: "steps/new",
            element: <UpdateTournamentStep />,
          },
          {
            path: "admins",
            element: <TournamentAdmins />,
          },
        ],
      },
      {
        path: "/tournaments/:tournamentId/steps/:stepId",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Tournament Step",
              items: [
                {
                  icon: IconArrowBack,
                  label: "Back",
                  path: "/tournaments/:tournamentId/steps",
                },
                {
                  icon: IconChartBar,
                  label: "Step Info",
                  path: "/tournaments/:tournamentId/steps/:stepId",
                },
                {
                  icon: IconSettings,
                  label: "Settings",
                  path: "/tournaments/:tournamentId/steps/:stepId/settings",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <TournamentStepHome />,
          },
          {
            path: "settings",
            element: <UpdateTournamentStep />,
          },
        ],
      },
      {
        path: "/players",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Players",
              items: [
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/players",
                },
                {
                  icon: IconUsersGroup,
                  label: "Players",
                  path: "/players/list",
                },
                {
                  icon: IconUserBolt,
                  label: "Identity Providers",
                  path: "/players/idp",
                },
                {
                  icon: IconStar,
                  label: "Skill Ratings",
                  path: "/players/mmr",
                },
                {
                  icon: IconMedal,
                  label: "Achievements",
                  path: "/players/achievements",
                  disabled: true,
                },
                {
                  icon: IconSword,
                  label: "Inventory",
                  path: "/players/inventory",
                  disabled: true,
                },
                {
                  icon: IconBook,
                  label: "Documentations",
                  path: "/players/documentations",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <PlayersHome />,
          },
          {
            path: "list",
            element: <PlayersList />,
          },
          {
            path: "idp",
            element: <IdentityProviders />,
          },
          {
            path: "idp/new",
            element: <UpdateIdentityProvider />,
          },
          {
            path: "idp/:idpId",
            element: <UpdateIdentityProvider />,
          },
          {
            path: ":playerId",
            element: <PlayerView />,
          },
          {
            path: "mmr",
            element: <SkillRatings />,
          },
          {
            path: "mmr/new",
            element: <CreateOrUpdateSkillRating />,
          },
          {
            path: "mmr/:skillRatingId",
            element: <CreateOrUpdateSkillRating />,
          },
          {
            path: "documentations",
            element: <PlayerDocumentation />,
          },
        ],
      },
      {
        path: "/gaming",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Gaming",
              items: [
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/gaming",
                },
                {
                  icon: IconPlanet,
                  label: "Matchmaking",
                  path: "/gaming/matchmaking",
                  disabled: true,
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <>Home TODO: Info about what are Gaming Services</>,
          },
          {
            path: "matchmaking",
            element: <>Coming soon</>,
          },
        ],
      },
      {
        path: "/limits",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Limits",
              items: [
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/limits",
                },
                {
                  icon: IconGauge,
                  label: "Limits",
                  path: "/limits/list",
                },
                {
                  icon: IconCreditCard,
                  label: "Subscription",
                  path: "/limits/subscription",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <LimitsHome />,
          },
          {
            path: "list",
            element: <Limits />,
          },
          {
            path: "subscription",
            element: <Subscription />,
          },
        ],
      },
      {
        path: "/leaderboards",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Leaderboards",
              items: [
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/leaderboards",
                },
                {
                  icon: IconGauge,
                  label: "Leaderboards",
                  path: "/leaderboards/list",
                },
                {
                  icon: IconBook,
                  label: "Documentations",
                  path: "/leaderboards/documentations",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <LeaderboardsHome />,
          },
          {
            path: "list",
            element: <Leaderboards />,
          },
          {
            path: "new",
            element: <UpdateLeaderboard />,
          },
          {
            path: "documentations",
            element: <LeaderboardDocumentation />,
          },
        ],
      },
      {
        path: "/leaderboards/:leaderboardId",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Leaderboard",
              items: [
                {
                  icon: IconArrowBack,
                  label: "Back",
                  path: "/leaderboards/list",
                },
                {
                  icon: IconDashboard,
                  label: "Dashboard",
                  path: "/leaderboards/:leaderboardId",
                },
                {
                  icon: IconTrophy,
                  label: "Current Leaderboard",
                  path: "/leaderboards/:leaderboardId/current",
                },
                {
                  icon: IconCalendarEvent,
                  label: "Seasons",
                  path: "/leaderboards/:leaderboardId/seasons",
                },
                {
                  icon: IconSettings,
                  label: "Settings",
                  path: "/leaderboards/:leaderboardId/settings",
                },
                {
                  icon: IconApps,
                  label: "Widgets",
                  path: "/leaderboards/:leaderboardId/widgets",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <LeaderboardDashboard />,
          },
          {
            path: "widgets",
            element: <LeaderboardWidgets />,
          },
          {
            path: "settings",
            element: <UpdateLeaderboard />,
          },
          {
            path: "current",
            element: <CurrentLeaderboard />,
          },
          {
            path: "seasons",
            element: <LeaderboardSeasons />,
          },
          {
            path: "seasons/:seasonId/buckets",
            element: <LeaderboardSeasonBuckets />,
          },
        ],
      },
      {
        path: "/platform",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Platforms",
              items: [
                {
                  icon: IconHome,
                  label: "Home",
                  path: "/platform",
                },
                {
                  icon: IconBrandPagekit,
                  label: "List",
                  path: "/platform/list",
                },
                {
                  icon: IconPaint,
                  label: "Themes",
                  path: "/platform/themes",
                  disabled: true,
                },
                {
                  icon: IconShoppingBag,
                  label: "Store",
                  path: "/platform/store",
                  disabled: true,
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <PlatformHome />,
          },
          {
            path: "list",
            element: <Platforms />,
          },
          {
            path: "new",
            element: <CreateOrUpdatePlatform />,
          },
        ],
      },
      {
        path: "/platform/:platformId",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "Platform",
              items: [
                {
                  icon: IconArrowBack,
                  label: "Back",
                  path: "/platform/list",
                },
                {
                  icon: IconHome,
                  label: "Dashboard",
                  path: "/platform/:platformId",
                },
                {
                  icon: IconApps,
                  label: "Files",
                  path: "/platform/:platformId/files",
                },
                {
                  icon: IconGlobe,
                  label: "Custom Domains",
                  path: "/platform/:platformId/domains",
                },
                {
                  icon: IconSettings,
                  label: "Settings",
                  path: "/platform/:platformId/settings",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <Platform />,
          },
          {
            path: "settings",
            element: <CreateOrUpdatePlatform />,
          },
          {
            path: "files",
            element: <PlatformFiles />,
          },
          {
            path: "files/new",
            element: <PlatformFileUpdate />,
          },
          {
            path: "files/:fileId",
            element: <PlatformFileUpdate />,
          },
          {
            path: "domains",
            element: <PlatformDomains />,
          },
          {
            path: "domains/new",
            element: <RegisterPlatformDomain />,
          },
        ],
      },
      {
        path: "/platform/:platformId/files/:fileId",
        element: (
          <ServiceWithMenuLayer
            navbarConfiguration={{
              title: "File",
              items: [
                {
                  icon: IconArrowBack,
                  label: "Back",
                  path: "/platform/:platformId/files",
                },
                {
                  icon: IconApps,
                  label: "Content",
                  path: "/platform/:platformId/files/:fileId",
                },
                {
                  icon: IconSettings,
                  label: "Settings",
                  path: "/platform/:platformId/files/:fileId/settings",
                },
              ],
            }}
          />
        ),
        children: [
          {
            path: "",
            element: <PlatformFileContent />,
          },
          {
            path: "settings",
            element: <PlatformFileUpdate />,
          },
        ],
      },
    ],
  },
]);

export const Layout = () => {
  const { authStatus } = useAuthenticator((context) => [context.route]);
  const url = new URL(window.location.href);
  const challenge = url.searchParams.get("login_challenge");

  useEffect(() => {
    if (authStatus !== "authenticated") return;
    if (!challenge) return;

    fetchAuthSession().then((session) => {
      if (!session.tokens?.idToken) return;

      fetch(
        (IS_LOCAL || IS_STG
          ? "https://idp.warrior.stg.well-played.gg/"
          : "https://idp.warrior.well-played.gg/") + "login/root",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            challenge,
            token: session.tokens.idToken.toString(),
          }),
        },
      )
        .then((data) => data.json())
        .then((body) => {
          const redirectTo = body?.redirectTo;
          if (!redirectTo) {
            notifications.show({
              title: "Error",
              message: JSON.stringify(body),
              variant: "error",
            });
            return;
          }
          window.location.replace(body.redirectTo);
        });
    });
  }, [authStatus]);

  if (authStatus !== "authenticated") {
    return (
      <Stack>
        <Center>
          <Image src="/logo-big.png" width={300} />
        </Center>
        <Authenticator loginMechanisms={["email"]} />
      </Stack>
    );
  }

  if (challenge) {
    return <>Redirecting...</>;
  }

  return (
    <OidcProvider
      configurationName="wp"
      configuration={{
        client_id: IS_LOCAL
          ? "0713a982-531c-4fac-97db-d501599b57bd"
          : IS_STG
          ? "fd534c2f-6801-4b7d-9dfd-fb9622b19062"
          : "a2af6215-9d0a-442d-87a0-1dea5cfc8ce0",
        redirect_uri: `${window.location.origin}/login/auth`,
        scope: "openid offline_access",
        authority:
          IS_LOCAL || IS_STG
            ? "https://oauth.warrior.stg.well-played.gg"
            : "https://oauth.warrior.well-played.gg",
      }}
    >
      <RouterProvider router={router} />
    </OidcProvider>
  );
};
