import { useNavigate, useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CreationForm } from "../../components/form/CreationForm";
import * as yup from "yup";
import {
  Accordion,
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Card,
  Center,
  Divider,
  Group,
  Loader,
  NumberInput,
  ScrollArea,
  Select,
  SimpleGrid,
  Stack,
  Stepper,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import {
  ConfigurationType,
  CreateTournamentStepInput,
  GroupConfiguration,
  GroupRepartitionMechanism,
  MatchScoreStatus,
  Player,
  SeedingInput,
  SeedingMechanism,
  StepConfiguration,
  StepStatus,
  StepType,
  Tournament,
  TournamentGameStatus,
  TournamentStep,
  TournamentTeam,
  TournamentTeamMemberDto,
  TournamentTeamStatus,
  UpdateMatchScoreInput,
} from "../../../../gql/graphql";
import { useEffect, useState } from "react";
import { isArray, isNumber, map } from "lodash";
import { IconChartBar, IconTrash, IconUsers } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { DataTable } from "mantine-datatable";
import { notifications } from "@mantine/notifications";
import { useTeamsFromTournament } from "../../../../business/teams.hook";
import { usePaginatedLoadAll } from "../../../../business/paginated-query.hook";
import { Connect, ConnectProvider } from "react-connect-lines";

const descriptions: Record<
  StepType,
  {
    title: string;
    description: string;
    locked?: boolean;
  }
> = {
  SCORE: {
    title: "Score",
    description:
      "Participants are given a score after each match. The winner is the one with the highest score.",
  },
  ROUND_ROBIN: {
    title: "Round robin",
    description:
      "Each participant plays against every other participant once. This format prioritizes fairness and equal opportunity, ensuring a comprehensive comparison of all competitors' skills and strategies.",
    locked: true,
  },
  SINGLE_ELIM: {
    title: "Single elimination",
    description:
      "Participants face off in matches where the loser is immediately eliminated. This continues until only one winner remains. It's a straightforward, high-stakes format ideal for short tournaments.",
  },
  DOUBLE_ELIM: {
    title: "Double elimination",
    description:
      "Participants are given a second chance after their first loss. They enter a losers' bracket, with a possibility to climb back and compete for the championship, ensuring a fairer, more inclusive competition.",
    locked: true,
  },
  CUSTOM: {
    title: "Custom",
    description: "You can create your own rules and scoring system.",
    locked: true,
  },
};

function calculateWinsNeeded(bestOf: number) {
  return Math.ceil((bestOf + 1) / 2);
}

function calculateBestOf(winsNeeded: number) {
  return winsNeeded * 2 - 1;
}

const GenerateTournamentStepModal = ({
  tournamentId,
  stepId,
}: {
  tournamentId: string;
  stepId: string;
}) => {
  const [teamsCount, setTeamsCount] = useState<number | undefined>();
  const [generating, setGenerating] = useState(false);
  const { data, loading } = useQuery<{
    tournamentStep: TournamentStep;
    tournament: Tournament;
  }>(
    gql`
      query ($tournamentId: ID!, $stepId: ID!) {
        tournamentStep(id: $stepId) {
          id
          name
          order
          status
          type
        }
        tournament(id: $tournamentId) {
          configuration {
            teamsCount
          }
          teams(status: CONFIRMED, page: {}) {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        tournamentId,
        stepId,
      },
    },
  );
  const [generateStep] = useMutation(gql`
    mutation ($stepId: ID!, $teamsCount: Int!) {
      generateTournamentStep(stepId: $stepId, teamsCount: $teamsCount)
    }
  `);

  if (loading) return <Loader />;

  return (
    <Stack>
      <Stack spacing={0}>
        <NumberInput
          value={teamsCount}
          min={0}
          max={data?.tournament?.teams?.totalCount ?? 0}
          onChange={(value) => {
            setTeamsCount(isNumber(value) ? value : 0);
          }}
          placeholder="Number of teams in this step"
        />
        <Anchor
          onClick={() => {
            setTeamsCount(data?.tournament?.teams?.totalCount ?? 0);
          }}
        >
          All confirmed teams ({data?.tournament?.teams?.totalCount})
        </Anchor>
      </Stack>
      <Button
        disabled={teamsCount === undefined}
        loading={generating}
        onClick={async () => {
          setGenerating(true);
          await generateStep({
            variables: {
              stepId,
              teamsCount,
            },
          })
            .then((data) => {
              if (data?.errors && data.errors.length > 0) {
                throw new Error(data.errors[0].message);
              }

              notifications.show({
                title: "Step generated",
                message: "The step has been generated",
                color: "green",
              });
              modals.closeAll();
            })
            .finally(() => {
              setGenerating(false);
            });
        }}
      >
        Generate
      </Button>
      {(teamsCount ?? 0) > (data?.tournament?.teams?.totalCount ?? 0) && (
        <Text color="red">
          You are trying to generate a step with more teams than the number of
          confirmed teams in the tournament. This will result in empty games
          generated.
        </Text>
      )}
    </Stack>
  );
};

const SelectTeamsModal = ({
  tournamentId,
  onSelectedTeams,
}: {
  tournamentId: string;
  onSelectedTeams: (
    teams: (Omit<TournamentTeam, "members"> & {
      members: TournamentTeamMemberDto & { player: Player }[];
    })[],
    seedingMechanism: SeedingMechanism,
    groupRepartitionMechanism: GroupRepartitionMechanism,
  ) => void;
}) => {
  const { loading, results } = useTeamsFromTournament({
    status: TournamentTeamStatus.Confirmed,
    tournamentId,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [groupRepartitionMechanism, setGroupRepartitionMechanism] = useState(
    GroupRepartitionMechanism.Balanced,
  );
  const [seedingMechanism, setSeedingMechanism] = useState(
    SeedingMechanism.None,
  );
  const [selectedRecords, setSelectedRecords] = useState<
    (Omit<TournamentTeam, "members"> & {
      members: TournamentTeamMemberDto & { player: Player }[];
    })[]
  >([]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Stack>
      <ScrollArea h={300}>
        <DataTable
          records={results}
          striped
          highlightOnHover
          withColumnBorders
          columns={[
            {
              accessor: "name",
              title: "Name",
            },
            {
              accessor: "",
              title: "Members",
              render: (record) => (
                <>
                  {record.members
                    .map((member) => member.player.username)
                    .join(",")}
                </>
              ),
            },
          ]}
          selectedRecords={selectedRecords}
          onSelectedRecordsChange={setSelectedRecords}
        />
      </ScrollArea>
      <Select
        label="Round Seeding Mechanism"
        value={seedingMechanism}
        onChange={(value) => {
          setSeedingMechanism(value as SeedingMechanism);
        }}
        data={Object.values(SeedingMechanism)}
      />
      <Select
        label="Group Repartition Mechanism"
        value={groupRepartitionMechanism}
        onChange={(value) => {
          setGroupRepartitionMechanism(value as GroupRepartitionMechanism);
        }}
        data={Object.values(GroupRepartitionMechanism)}
      />
      <Button
        disabled={selectedRecords.length === 0}
        onClick={() => {
          setButtonLoading(true);
          onSelectedTeams(
            selectedRecords,
            seedingMechanism,
            groupRepartitionMechanism,
          );
        }}
        loading={buttonLoading}
      >
        Add teams
      </Button>
    </Stack>
  );
};

const ScoreStatusBadge = ({ status }: { status: MatchScoreStatus }) => {
  const color =
    status === MatchScoreStatus.Forfeit || status === MatchScoreStatus.Loser
      ? "red"
      : status === MatchScoreStatus.Winner
      ? "green"
      : "yellow";

  return <Badge color={color}>{status}</Badge>;
};

const UpdateTeamScoreModal = ({
  score,
  matchId,
  team,
}: {
  score: {
    teamId: string;
    status: MatchScoreStatus;
    score: number;
    matchId: string;
  };
  team: Omit<TournamentTeam, "members"> & {
    members: TournamentTeamMemberDto & { player: Player }[];
  };
  matchId: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [scoreValue, setScore] = useState(score.score);
  const [status, setStatus] = useState(score.status);
  const [updateMatchScores] = useMutation<
    {
      updateMatchScores: { id: string };
    },
    {
      matchId: string;
      input: UpdateMatchScoreInput[];
    }
  >(gql`
    mutation ($matchId: ID!, $input: [UpdateMatchScoreInput!]!) {
      updateMatchScores(matchId: $matchId, input: $input) {
        id
      }
    }
  `);

  return (
    <Stack h={300} justify="space-between">
      <Text>Update the score for {team.name}</Text>
      <NumberInput
        label="Score"
        value={scoreValue}
        onChange={(value) => {
          setScore(isNumber(value) ? value : 0);
        }}
      />
      <Select
        label="Team status"
        data={Object.values(MatchScoreStatus)}
        value={status}
        onChange={(value) => {
          setStatus(value as MatchScoreStatus);
        }}
      />
      <Button
        loading={loading}
        onClick={() => {
          setLoading(true);
          updateMatchScores({
            variables: {
              matchId,
              input: [
                {
                  teamId: team.id,
                  forcedScoreValue: scoreValue,
                  status,
                },
              ],
            },
          })
            .then((data) => {
              if (data?.errors && data.errors.length > 0) {
                throw new Error(data.errors[0].message);
              }

              modals.closeAll();
            })
            .finally(() => setLoading(false));
        }}
      >
        Update score
      </Button>
    </Stack>
  );
};

const STEPPER_CFG: Record<StepStatus, number> = {
  CONFIGURED: 1,
  GENERATING: 1,
  GENERATED: 2,
  SEEDING: 2,
  SEEDED: 3,
  STARTED: 4,
  ENDED: 5,
};

export const TournamentStepHome = () => {
  const { stepId, tournamentId } = useParams();
  const [selectedTeams, setSelectedTeams] = useState<
    (Omit<TournamentTeam, "members"> & {
      members: TournamentTeamMemberDto & { player: Player }[];
    })[]
  >([]);
  const { loading: loadingStepShape, data: stepShape } = useQuery<{
    tournamentStepGeneratedShape: {
      id: string;
      name: string;
      rounds: {
        id: string;
        name: string;
        order: number;
        games: {
          id: string;
          order: number;
          status: TournamentGameStatus;
          losingGameId: string;
          winningGameId: string;
          matches: {
            id: string;
            order: number;
            status: MatchScoreStatus;
          }[];
        }[];
      }[];
    }[];
  }>(
    gql`
      query ($stepId: ID!) {
        tournamentStepGeneratedShape(stepId: $stepId) {
          id
          name
          rounds {
            id
            name
            order
            games {
              id
              order
              status
              losingGameId
              winningGameId
              matches {
                id
                order
                status
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        stepId,
      },
    },
  );
  const {
    loading: loadingScores,
    results: scores,
    refetch,
  } = usePaginatedLoadAll<
    any,
    any,
    {
      teamId: string;
      status: MatchScoreStatus;
      score: number;
      matchId: string;
    }
  >(
    gql`
      query ($stepId: ID!, $page: PageInfo!) {
        tournamentStepGroupRoundGameMatchScoresGetForStep(
          stepId: $stepId
          page: $page
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            teamId
            status
            score
            matchId
          }
        }
      }
    `,
    {
      variables: {
        stepId,
      },
    },
  );
  const { loading: teamsLoading, results: teams } = useTeamsFromTournament({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    tournamentId: tournamentId!,
    status: TournamentTeamStatus.Confirmed,
  });
  const dataLoading = loadingStepShape || loadingScores || teamsLoading;
  const { data, loading } = useQuery<{
    tournamentStep: TournamentStep;
  }>(
    gql`
      query ($id: ID!) {
        tournamentStep(id: $id) {
          id
          name
          description
          type
          status
          order
          tournamentId
          configuration {
            groups {
              rounds {
                order
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: stepId,
      },
      skip: dataLoading,
      onCompleted: (data) => {
        setSelectedTeams(
          teams.filter((team) =>
            scores.some((score) => score.teamId === team.id),
          ),
        );
      },
    },
  );
  const [seedTeams] = useMutation<
    {
      seedTournamentStep: boolean;
    },
    { stepId: string; input: SeedingInput }
  >(gql`
    mutation ($stepId: ID!, $input: SeedingInput!) {
      seedTournamentStep(stepId: $stepId, input: $input)
    }
  `);
  const [startStep] = useMutation<
    {
      startTournamentStep: boolean;
    },
    { stepId: string }
  >(gql`
    mutation ($stepId: ID!) {
      startTournamentStep(stepId: $stepId)
    }
  `);

  if (dataLoading || loading) return <Loader />;

  const selectedTeamsSet = new Set(selectedTeams.map((team) => team.id));
  return (
    <Stack>
      <Stack>
        <Title>Dashboard</Title>
        <Stepper
          active={
            STEPPER_CFG[data?.tournamentStep.status ?? StepStatus.Configured]
          }
        >
          <Stepper.Step label="Configured" />
          <Stepper.Step
            label="Generated"
            loading={data?.tournamentStep.status === StepStatus.Generating}
          />
          <Stepper.Step
            label="Seeded"
            loading={data?.tournamentStep.status === StepStatus.Seeding}
          />
          <Stepper.Step label="Started" />
        </Stepper>
      </Stack>
      <SimpleGrid cols={2}>
        <Card pt={0}>
          {(loading && <Loader />) || (
            <>
              <Card.Section>
                <Group position="apart" p={10}>
                  <Group spacing={10}>
                    <IconChartBar />
                    <Text size="md" weight={700}>
                      {data?.tournamentStep?.name}
                    </Text>
                  </Group>
                  <Badge
                    color={
                      data?.tournamentStep.status === StepStatus.Started
                        ? "green"
                        : data?.tournamentStep.status === StepStatus.Ended
                        ? "red"
                        : "yellow"
                    }
                  >
                    {data?.tournamentStep.status}
                  </Badge>
                </Group>
              </Card.Section>
              <Stack>
                {data?.tournamentStep.description}
                {data?.tournamentStep.status === StepStatus.Seeded && (
                  <Button
                    color="green"
                    onClick={() => {
                      modals.openConfirmModal({
                        title: "Start step",
                        id: "start-step",
                        children: (
                          <>
                            <Text>
                              Are you sure you want to start this step?
                            </Text>
                            <Text>
                              You will not be able to edit it anymore.
                            </Text>
                          </>
                        ),
                        confirmProps: { color: "green" },
                        labels: {
                          cancel: "Cancel",
                          confirm: "Start",
                        },
                        centered: true,
                        onConfirm: () => {
                          startStep({
                            variables: {
                              stepId: stepId as string,
                            },
                          }).then((data) => {
                            if (data?.errors && data.errors.length > 0) {
                              throw new Error(data.errors[0].message);
                            }

                            refetch({
                              id: stepId,
                            }).then();
                          });
                          modals.closeAll();
                        },
                      });
                    }}
                  >
                    Start step
                  </Button>
                )}
              </Stack>
            </>
          )}
        </Card>
        <Card pt={0}>
          {(loading && <Loader />) || (
            <>
              <Card.Section>
                <Group position="apart" p={10}>
                  <Group spacing={10}>
                    <IconChartBar />
                    <Text size="md" weight={700}>
                      Configuration
                    </Text>
                  </Group>
                  <Button
                    disabled={
                      data?.tournamentStep.status !== StepStatus.Configured
                    }
                    onClick={() => {
                      if (
                        !data?.tournamentStep.configuration?.groups?.length ||
                        data?.tournamentStep.configuration?.groups?.some(
                          (group) => !group.rounds?.length,
                        )
                      ) {
                        modals.open({
                          title: "Error",
                          id: "error",
                          centered: true,
                          children: (
                            <Stack>
                              <Text>
                                You cannot generate a step that has no groups or
                                groups with no rounds.
                              </Text>
                              <Text>
                                Please add at least one group with at least one
                                round.
                              </Text>
                            </Stack>
                          ),
                        });
                        return;
                      }
                      modals.open({
                        title: "Generate step",
                        id: "generate-step",
                        centered: true,
                        onClose() {
                          refetch({
                            id: stepId,
                          }).then();
                        },
                        children: (
                          <GenerateTournamentStepModal
                            tournamentId={data?.tournamentStep.tournamentId}
                            stepId={data?.tournamentStep.id}
                          />
                        ),
                      });
                    }}
                  >
                    Generate
                  </Button>
                </Group>
              </Card.Section>
              <SimpleGrid cols={2}>
                <Stack spacing={0}>
                  <Text weight={700}>Type</Text>
                  <Text>{data?.tournamentStep.type}</Text>
                </Stack>
                <Stack spacing={0}>
                  <Text weight={700}>Order</Text>
                  <Text>{data?.tournamentStep.order}</Text>
                </Stack>
                {data?.tournamentStep.type !== StepType.SingleElim &&
                  data?.tournamentStep.type !== StepType.DoubleElim && (
                    <Stack spacing={0}>
                      <Text weight={700}>Groups/Pools</Text>
                      <Text>
                        {stepShape?.tournamentStepGeneratedShape.length}
                      </Text>
                    </Stack>
                  )}
              </SimpleGrid>
            </>
          )}
        </Card>
      </SimpleGrid>
      {data?.tournamentStep.status !== StepStatus.Configured && (
        <Card pt={0}>
          {(loading && <Loader />) || (
            <>
              <Card.Section>
                <Group position="apart" p={10}>
                  <Group spacing={10}>
                    <IconUsers />
                    <Text size="md" weight={700}>
                      Brackets
                    </Text>
                  </Group>
                  <Button
                    disabled={
                      data?.tournamentStep.status === StepStatus.Started ||
                      data?.tournamentStep.status === StepStatus.Ended
                    }
                    onClick={() => {
                      if (
                        data?.tournamentStep.status !== StepStatus.Generated &&
                        data?.tournamentStep.status !== StepStatus.Seeded
                      ) {
                        modals.open({
                          title: "Error",
                          id: "error",
                          centered: true,
                          children: (
                            <Stack>
                              <Text>
                                You cannot seed a step that has not been
                                generated.
                              </Text>
                              <Text>
                                Please generate the step before seeding it.
                              </Text>
                            </Stack>
                          ),
                        });
                        return;
                      }
                      modals.open({
                        title: "Generate step",
                        id: "generate-step",
                        centered: true,
                        children: (
                          <SelectTeamsModal
                            onSelectedTeams={(
                              teams,
                              seedingMechanism,
                              groupRepartitionMechanism,
                            ) => {
                              const newTeams = selectedTeams.concat(
                                teams.filter(
                                  (team) => !selectedTeamsSet.has(team.id),
                                ),
                              );
                              seedTeams({
                                variables: {
                                  stepId: stepId as string,
                                  input: {
                                    automaticSeeding: {
                                      seedingMechanism,
                                      groupRepartitionMechanism,
                                      teams: newTeams.map((team) => team.id),
                                    },
                                  },
                                },
                              })
                                .then((data) => {
                                  if (data?.errors && data.errors.length > 0) {
                                    throw new Error(data.errors[0].message);
                                  }

                                  setSelectedTeams(
                                    newTeams.filter(
                                      (team) => !selectedTeamsSet.has(team.id),
                                    ),
                                  );

                                  refetch({
                                    id: stepId,
                                  }).then();
                                })
                                .finally(() => modals.closeAll());
                            }}
                            tournamentId={data?.tournamentStep.tournamentId}
                          />
                        ),
                      });
                    }}
                  >
                    Select teams
                  </Button>
                </Group>
              </Card.Section>
              {(data?.tournamentStep.type /*=== StepType.Score*/ && (
                <SimpleGrid>
                  <ConnectProvider>
                    {stepShape?.tournamentStepGeneratedShape?.map((group) => (
                      <Card withBorder={true} key={group.id}>
                        <Card.Section>
                          <Center>
                            <Title order={2} size="h3">
                              Group: {group.name}
                            </Title>
                          </Center>
                          <Divider />
                        </Card.Section>
                        <Group mt={10}>
                          {group.rounds.map((round) => (
                            <Card withBorder={true} key={round.id}>
                              <Card.Section>
                                <Center>
                                  <Title order={3} size="h4">
                                    Round: {round.name}
                                  </Title>
                                </Center>
                                <Divider />
                              </Card.Section>
                              <Stack spacing={0} mt={10}>
                                {Array.from(round.games)
                                  .sort((g1, g2) => g1.order - g2.order)
                                  .flatMap((game) => (
                                    <Connect
                                      id={game.id}
                                      key={game.id}
                                      connectWith={[
                                        ...(game.losingGameId
                                          ? [
                                              {
                                                id: game.losingGameId,
                                                color: "red",
                                              },
                                            ]
                                          : []),
                                        ...(game.winningGameId
                                          ? [
                                              {
                                                id: game.winningGameId,
                                                color: "green",
                                              },
                                            ]
                                          : []),
                                      ]}
                                    >
                                      <Card withBorder={true} key={game.id}>
                                        <Card.Section>
                                          <Center>
                                            <Title order={4} size="h5">
                                              Game: {game.order} - {game.status}
                                            </Title>
                                          </Center>
                                          <Divider />
                                        </Card.Section>
                                        <Center mt={10}>
                                          <Stack spacing={0}>
                                            {scores
                                              .filter(
                                                (score) =>
                                                  selectedTeamsSet.has(
                                                    score.teamId,
                                                  ) &&
                                                  score.matchId ===
                                                    game.matches[0]?.id,
                                              )
                                              .map((score) => {
                                                const team = teams.find(
                                                  (team) =>
                                                    team.id === score.teamId,
                                                );

                                                if (!team) return null;

                                                return (
                                                  <Group position="apart">
                                                    {(data?.tournamentStep
                                                      .status ===
                                                      StepStatus.Started && (
                                                      <Anchor
                                                        onClick={() => {
                                                          modals.open({
                                                            title: `Update ${team.name}'s score`,
                                                            id: "update-score",
                                                            centered: true,
                                                            onClose() {
                                                              refetch({
                                                                id: stepId,
                                                              }).then();
                                                            },
                                                            children: (
                                                              <UpdateTeamScoreModal
                                                                score={score}
                                                                matchId={
                                                                  game
                                                                    .matches[0]
                                                                    .id
                                                                }
                                                                team={team}
                                                              />
                                                            ),
                                                          });
                                                        }}
                                                      >
                                                        {team.name}
                                                      </Anchor>
                                                    )) || (
                                                      <Text>{team.name}</Text>
                                                    )}
                                                    {data?.tournamentStep
                                                      .status ===
                                                      StepStatus.Started && (
                                                      <>
                                                        <ScoreStatusBadge
                                                          status={score.status}
                                                        />
                                                        <Text>
                                                          {score.score}
                                                        </Text>
                                                      </>
                                                    )}
                                                    {(data?.tournamentStep
                                                      .status ===
                                                      StepStatus.Generated ||
                                                      data?.tournamentStep
                                                        .status ===
                                                        StepStatus.Seeded) && (
                                                      <ActionIcon
                                                        color="red"
                                                        onClick={() => {
                                                          setSelectedTeams(
                                                            selectedTeams.filter(
                                                              (teamFilter) =>
                                                                teamFilter.id !==
                                                                team.id,
                                                            ),
                                                          );
                                                        }}
                                                      >
                                                        <IconTrash />
                                                      </ActionIcon>
                                                    )}
                                                  </Group>
                                                );
                                              })}
                                          </Stack>
                                        </Center>
                                      </Card>
                                    </Connect>
                                  ))}
                              </Stack>
                            </Card>
                          ))}
                        </Group>
                      </Card>
                    ))}
                  </ConnectProvider>
                </SimpleGrid>
              )) || <>TODO</>}
            </>
          )}
        </Card>
      )}
    </Stack>
  );
};

export const UpdateTournamentStep = () => {
  const navigate = useNavigate();
  const { stepId, tournamentId } = useParams();
  const mantineTheme = useMantineTheme();
  const [selectedStepType, setSelectedStepType] = useState<StepType | null>(
    null,
  );
  const [userSelectedStepType, setUserSelectedStepType] =
    useState<StepType | null>(null);
  const { data, loading } = useQuery<{
    tournamentStep: TournamentStep;
  }>(
    gql`
      query ($id: ID!) {
        tournamentStep(id: $id) {
          id
          name
          description
          type
          status
          order
          configuration {
            groups {
              name
              description
              rounds {
                name
                order
                game {
                  teamsCount
                  matchConfiguration {
                    scoreFormula
                    variables {
                      defaultValue
                      displayIcon
                      displayName
                      formulaName
                    }
                  }
                  useMatchScoresAsGameScore
                  wonMatchCountToWinGame
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: stepId,
      },
      skip: !stepId,
      onCompleted: (data) => {
        setSelectedStepType(data.tournamentStep.type);
      },
    },
  );
  const [createTournamentStep] = useMutation<{
    createTournamentStep: { id: string };
  }>(gql`
    mutation (
      $tournamentId: ID!
      $step: CreateTournamentStepInput!
      $configuration: StepConfigurationInput!
    ) {
      createTournamentStep(
        tournamentId: $tournamentId
        step: $step
        configuration: $configuration
      ) {
        id
      }
    }
  `);
  const [updateTournamentStep] = useMutation<{
    updateTournamentStep: { id: string };
  }>(gql`
    mutation (
      $stepId: ID!
      $step: CreateTournamentStepInput!
      $configuration: StepConfigurationInput!
    ) {
      updateTournamentStep(
        stepId: $stepId
        configuration: $configuration
        step: $step
      ) {
        id
      }
    }
  `);

  if (loading) return <Loader />;

  if (!selectedStepType) {
    return (
      <Stack>
        <Title>Select a step type</Title>
        <SimpleGrid cols={4}>
          {map(
            descriptions,
            ({ title, description, locked }, stepType: StepType) => (
              <Card
                withBorder={true}
                onClick={() => {
                  if (locked) return;

                  setUserSelectedStepType(stepType);
                }}
                style={{
                  cursor: locked ? "not-allowed" : "pointer",
                  opacity: locked ? 0.5 : 1,
                  borderColor:
                    userSelectedStepType === stepType
                      ? mantineTheme.colors.cyan[7]
                      : undefined,
                }}
              >
                <Text fw={500}>{title}</Text>

                <ScrollArea h={100}>
                  <Text size="sm" c="dimmed">
                    {description}
                  </Text>
                </ScrollArea>
              </Card>
            ),
          )}
        </SimpleGrid>
        <Button
          disabled={!userSelectedStepType}
          onClick={() => setSelectedStepType(userSelectedStepType)}
        >
          Continue
        </Button>
      </Stack>
    );
  }

  return (
    <CreationForm
      title="Tournament step"
      submitText={stepId ? "Update step" : "Create a new step"}
      schemaDefinition={{
        name: {
          type: "INPUT",
          inputType: "text",
          label: "Name",
          info: "The name of the step",
          yupConfig: yup.string().required(),
          defaultValue: data?.tournamentStep?.name,
        },
        description: {
          type: "INPUT",
          inputType: "text",
          label: "Description",
          info: "The description of the step",
          yupConfig: yup.string().required(),
          defaultValue: data?.tournamentStep?.description,
        },
        order: {
          type: "INPUT",
          inputType: "number",
          label: "Order",
          info: "The order of the step",
          yupConfig: yup.number().required(),
          defaultValue: data?.tournamentStep?.order,
        },
        ...(selectedStepType !== StepType.DoubleElim &&
        selectedStepType !== StepType.SingleElim
          ? {
              groups: {
                type: "DYNAMIC",
                label: "Groups",
                /*
                name: "Group name 1",
                    description: "Test",
                    rounds: [
                      {
                        name: "Round name",
                        order: 1,
                        game: {
                          teamsCount: 1,
                          matchConfiguration: {
                            scoreFormula: "lol",
                            variables: [{
                              defaultValue: 0,
                              displayIcon: "https://cdn.discordapp.com/attachments/881978978077696768/881979000777459712/unknown.png",
                              displayName: "test",
                              formulaName: "test",
                            }],
                          },
                          useMatchScoresAsGameScore: true,
                          wonMatchCountToWinGame: 2,
                        },
                      },
                    ],
                 */
                yupConfig: yup.array().of(
                  yup.object().shape({
                    name: yup.string().required(),
                    rounds: yup.array().of(
                      yup.object().shape({
                        name: yup.string().required(),
                        order: yup.number().required(),
                        game: yup.object().shape({
                          teamsCount: yup.number(),
                          matchConfiguration: yup.object().shape({
                            scoreFormula: yup.string(),
                            variables: yup.array().of(
                              yup.object().shape({
                                defaultValue: yup.number().required(),
                                displayIcon: yup.string(),
                                displayName: yup.string().required(),
                                formulaName: yup.string().required(),
                              }),
                            ),
                          }),
                          useMatchScoresAsGameScore: yup.boolean().required(),
                          wonMatchCountToWinGame: yup.number().required(),
                        }),
                      }),
                    ),
                  }),
                ),
                defaultValue: data?.tournamentStep?.configuration?.groups || [],
                element: (onChange) => {
                  const [groups, setGroups] = useState<GroupConfiguration[]>(
                    data?.tournamentStep?.configuration?.groups?.map((data) => {
                      const newObj = Object.assign({}, data);
                      delete newObj.__typename;
                      newObj.rounds = newObj.rounds.map((round) => {
                        const newObj = Object.assign({}, round);
                        delete newObj.__typename;

                        if (newObj.game) {
                          newObj.game = Object.assign({}, newObj.game);
                          delete newObj.game.__typename;

                          if (newObj.game.matchConfiguration) {
                            newObj.game.matchConfiguration = Object.assign(
                              {},
                              newObj.game.matchConfiguration,
                            );
                            delete newObj.game.matchConfiguration.__typename;

                            if (newObj.game.matchConfiguration.variables) {
                              newObj.game.matchConfiguration.variables =
                                newObj.game.matchConfiguration.variables.map(
                                  (variable) => {
                                    const newObj = Object.assign({}, variable);
                                    delete newObj.__typename;
                                    return newObj;
                                  },
                                );
                            }
                          }
                        }
                        return newObj;
                      });
                      return newObj;
                    }) || [],
                  );

                  useEffect(() => {
                    onChange(groups);
                  }, [groups]);

                  return (
                    <>
                      <Title order={1} size="h2">
                        Groups
                      </Title>
                      <Button
                        onClick={() => {
                          setGroups(
                            groups.concat({
                              name: "Group #" + (groups.length + 1),
                              rounds: [],
                            }),
                          );
                        }}
                      >
                        Add a group
                      </Button>
                      <Accordion>
                        {groups.map((group, index) => (
                          <Accordion.Item key={index} value={index + ""}>
                            <Accordion.Control>{group.name}</Accordion.Control>
                            <Accordion.Panel>
                              <Stack>
                                <Group position="apart">
                                  <TextInput
                                    label="Group name"
                                    placeholder="Group name"
                                    value={group.name}
                                    onChange={(e) => {
                                      const newGroupConfiguration = (
                                        [] as GroupConfiguration[]
                                      ).concat(groups);
                                      newGroupConfiguration[index].name =
                                        e.target.value;
                                      setGroups(newGroupConfiguration);
                                    }}
                                  />
                                  <Tooltip label="Delete">
                                    <ActionIcon
                                      onClick={() => {
                                        modals.openConfirmModal({
                                          title: "Delete group",
                                          id: "delete-group",
                                          children:
                                            "Are you sure you want to delete this group?",
                                          confirmProps: { color: "red" },
                                          labels: {
                                            cancel: "Cancel",
                                            confirm: "Delete",
                                          },
                                          centered: true,
                                          onConfirm: () => {
                                            setGroups(
                                              groups.filter(
                                                (_, i) => i !== index,
                                              ),
                                            );
                                          },
                                        });
                                      }}
                                      color="red"
                                      variant="outline"
                                    >
                                      <IconTrash />
                                    </ActionIcon>
                                  </Tooltip>
                                </Group>
                                <Textarea
                                  label="Description"
                                  placeholder="A short description for your group"
                                  onChange={(e) => {
                                    const newGroupConfiguration = (
                                      [] as GroupConfiguration[]
                                    ).concat(groups);
                                    newGroupConfiguration[index].description =
                                      e.target.value;
                                    setGroups(newGroupConfiguration);
                                  }}
                                >
                                  {group.description}
                                </Textarea>
                                <Title order={2} size="h3">
                                  Rounds
                                </Title>
                                <Button
                                  onClick={() => {
                                    const newGroupConfiguration = (
                                      [] as GroupConfiguration[]
                                    ).concat(groups);
                                    newGroupConfiguration[index].rounds.push({
                                      name:
                                        "Round #" + (group.rounds.length + 1),
                                      order: group.rounds.length + 1,
                                      game: {
                                        teamsCount: 2,
                                        matchConfiguration: {
                                          scoreFormula: "",
                                          variables: [],
                                        },
                                        useMatchScoresAsGameScore: true,
                                        wonMatchCountToWinGame: 1,
                                      },
                                    });
                                    setGroups(newGroupConfiguration);
                                  }}
                                >
                                  Add a round
                                </Button>
                                <Accordion>
                                  {group.rounds.map((round, roundIndex) => (
                                    <Accordion.Item
                                      key={roundIndex}
                                      value={roundIndex + ""}
                                    >
                                      <Accordion.Control>
                                        {round.name}
                                      </Accordion.Control>
                                      <Accordion.Panel>
                                        <Stack>
                                          <Group position="apart">
                                            <TextInput
                                              label="Round name"
                                              placeholder="Round name"
                                              value={round.name}
                                              onChange={(e) => {
                                                const newGroupConfiguration = (
                                                  [] as GroupConfiguration[]
                                                ).concat(groups);
                                                newGroupConfiguration[
                                                  index
                                                ].rounds[roundIndex].name =
                                                  e.target.value;
                                                setGroups(
                                                  newGroupConfiguration,
                                                );
                                              }}
                                            />
                                            <Tooltip label="Delete">
                                              <ActionIcon
                                                onClick={() => {
                                                  modals.openConfirmModal({
                                                    title: "Delete round",
                                                    id: "delete-round",
                                                    children:
                                                      "Are you sure you want to delete this round?",
                                                    confirmProps: {
                                                      color: "red",
                                                    },
                                                    labels: {
                                                      cancel: "Cancel",
                                                      confirm: "Delete",
                                                    },
                                                    centered: true,
                                                    onConfirm: () => {
                                                      const newGroupConfiguration =
                                                        (
                                                          [] as GroupConfiguration[]
                                                        ).concat(groups);
                                                      newGroupConfiguration[
                                                        index
                                                      ].rounds =
                                                        newGroupConfiguration[
                                                          index
                                                        ].rounds.filter(
                                                          (_, i) =>
                                                            i !== roundIndex,
                                                        );
                                                      setGroups(
                                                        newGroupConfiguration,
                                                      );
                                                    },
                                                  });
                                                }}
                                                color="red"
                                                variant="outline"
                                              >
                                                <IconTrash />
                                              </ActionIcon>
                                            </Tooltip>
                                          </Group>
                                          <Title order={3} size="h4">
                                            Game
                                          </Title>
                                          <Group position="apart">
                                            <NumberInput
                                              disabled={
                                                true /* TODO Check depending on config */
                                              }
                                              label="Teams count"
                                              placeholder="Teams count"
                                              min={2}
                                              max={4096}
                                              value={round.game.teamsCount}
                                              onChange={(e) => {
                                                const newGroupConfiguration = (
                                                  [] as GroupConfiguration[]
                                                ).concat(groups);
                                                newGroupConfiguration[
                                                  index
                                                ].rounds[
                                                  roundIndex
                                                ].game.teamsCount = parseInt(
                                                  e.toString(),
                                                );
                                                setGroups(
                                                  newGroupConfiguration,
                                                );
                                              }}
                                            />
                                            <TextInput
                                              label="Best of"
                                              placeholder="Best of number"
                                              value={calculateBestOf(
                                                round.game
                                                  .wonMatchCountToWinGame,
                                              )}
                                              onChange={(e) => {
                                                const newGroupConfiguration = (
                                                  [] as GroupConfiguration[]
                                                ).concat(groups);
                                                newGroupConfiguration[
                                                  index
                                                ].rounds[
                                                  roundIndex
                                                ].game.wonMatchCountToWinGame =
                                                  calculateWinsNeeded(
                                                    parseInt(e.target.value),
                                                  );
                                                setGroups(
                                                  newGroupConfiguration,
                                                );
                                              }}
                                            />
                                          </Group>
                                          <Title order={4} size="h5">
                                            Match configuration
                                          </Title>
                                          <Text italic={true}>
                                            Not available yet
                                          </Text>
                                          {/*<Group position="apart">
                                      <TextInput
                                        label="Score formula"
                                        placeholder="Score formula"
                                        value={
                                          round.game.matchConfiguration
                                            .scoreFormula
                                        }
                                        onChange={(e) => {
                                          const newGroupConfiguration = (
                                            [] as GroupConfiguration[]
                                          ).concat(groups);
                                          newGroupConfiguration[index].rounds[
                                            roundIndex
                                          ].game.matchConfiguration.scoreFormula =
                                            e.target.value;
                                          setGroups(newGroupConfiguration);
                                        }}
                                      />
                                      <TextInput
                                        label="Use match scores as game score"
                                        placeholder="Use match scores as game score"
                                        value={
                                          round.game.useMatchScoresAsGameScore
                                        }
                                        onChange={(e) => {
                                          const newGroupConfiguration = (
                                            [] as GroupConfiguration[]
                                          ).concat(groups);
                                          newGroupConfiguration[index].rounds[
                                            roundIndex
                                          ].game.useMatchScoresAsGameScore =
                                            e.target.value;
                                          setGroups(newGroupConfiguration);
                                        }}
                                      />
                                    </Group>
                                    <Title order={4} size="h5">
                                      Variables
                                    </Title>
                                    <Button
                                      onClick={() => {
                                        const newGroupConfiguration = (
                                          [] as GroupConfiguration[]
                                        ).concat(groups);
                                        newGroupConfiguration[index].rounds[
                                          roundIndex
                                        ].game.matchConfiguration.variables.push(
                                          {
                                            defaultValue: 0,
                                            displayIcon:
                                              "https://cdn.discordapp.com/attachments/881978978077696768/881979000777459712/unknown.png",
                                            displayName: "test",
                                            formulaName: "test",
                                          },
                                        );
                                        setGroups(newGroupConfiguration);
                                      }}
                                    >
                                      Add a variable
                                    </Button>
                                    <DataTable
                                      columns={[
                                        {
                                          accessor: "displayName",
                                          title: "Display name",
                                        },
                                        {
                                          accessor: "defaultValue",
                                          title: "Default value",
                                        },
                                        {
                                          accessor: "formulaName",
                                          title: "Formula name",
                                        },
                                      ]}
                                      data={
                                        round.game.matchConfiguration.variables
                                      }
                                      actions={[
                                        {
                                          icon: <IconTrash />,
                                          onClick: (row) => {
                                            const newGroupConfiguration = (
                                              [] as GroupConfiguration[]
                                            ).concat(groups);
                                            newGroupConfiguration[index].rounds[
                                              roundIndex
                                            ].game.matchConfiguration.variables =
                                              newGroupConfiguration[
                                                index
                                              ].rounds[
                                                roundIndex
                                              ].game.matchConfiguration.variables.filter(
                                                (_, i) => i !== row.index,
                                              );
                                            setGroups(newGroupConfiguration);
                                          },
                                        },
                                      ]}
                                    />*/}
                                        </Stack>
                                      </Accordion.Panel>
                                    </Accordion.Item>
                                  ))}
                                </Accordion>
                              </Stack>
                            </Accordion.Panel>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </>
                  );
                },
              },
            }
          : {
              bestOf: {
                type: "INPUT",
                inputType: "number",
                label: "Best of",
                info: "The number of matches to win a game",
                yupConfig: yup.number().required(),
                defaultValue:
                  data?.tournamentStep?.configuration?.groups[0]?.rounds[0]
                    ?.game?.wonMatchCountToWinGame,
              },
            }),
      }}
      onSubmit={async (values: any) => {
        const step: CreateTournamentStepInput = {
          name: values.name,
          description: values.description,
          order: values.order,
          type: selectedStepType,
        };

        const configuration: StepConfiguration = {
          type: ConfigurationType.Step,
          groups: !values.groups
            ? [
                {
                  name: "Group",
                  description: "Tree group",
                  rounds: [
                    {
                      name: "Round config",
                      order: 0,
                      game: {
                        teamsCount: 2,
                        matchConfiguration: {
                          scoreFormula: "",
                          variables: [],
                        },
                        useMatchScoresAsGameScore: true,
                        wonMatchCountToWinGame: values.bestOf,
                      },
                    },
                  ],
                },
              ]
            : values.groups,
        };

        if (stepId) {
          if (
            !(await new Promise((resolve) => {
              if (
                data?.tournamentStep.status !== StepStatus.Configured &&
                data?.tournamentStep.status !== StepStatus.Ended
              ) {
                modals.openConfirmModal({
                  title: "Warning",
                  id: "warning",
                  children:
                    "You are about to update a step that has already been generated. This will reset the step configuration and remove all your generated content. Are you sure you want to continue?",
                  confirmProps: { color: "red" },
                  labels: {
                    cancel: "Cancel",
                    confirm: "Continue",
                  },
                  centered: true,
                  onConfirm: () => {
                    resolve(true);
                  },
                  onClose: () => {
                    resolve(false);
                  },
                });
              } else if (data?.tournamentStep.status === StepStatus.Ended) {
                notifications.show({
                  title: "Step ended",
                  message: "You cannot update a step that has ended",
                  color: "red",
                });
                resolve(false);
              } else {
                resolve(true);
              }
            }))
          ) {
            return;
          }

          await updateTournamentStep({
            variables: {
              stepId,
              step,
              configuration,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
            return data.data?.updateTournamentStep.id;
          });
          notifications.show({
            title: "Step updated",
            message: "The step has been updated",
            color: "green",
          });
        } else {
          const createdStep = await createTournamentStep({
            variables: {
              tournamentId,
              step,
              configuration,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
            return data.data?.createTournamentStep.id;
          });
          notifications.show({
            title: "Step created",
            message: "The step has been created",
            color: "green",
          });
          navigate(`../steps/${createdStep}`);
        }
      }}
    />
  );
};

export const TournamentSteps = () => {
  const navigate = useNavigate();
  const tournamentId = useParams().tournamentId;
  const { data, loading, refetch } = useQuery<{
    tournamentSteps: TournamentStep[];
  }>(
    gql`
      query ($tournamentId: ID!) {
        tournamentSteps(tournamentId: $tournamentId) {
          id
          name
          order
          status
          type
          tournamentId
          configuration {
            groups {
              rounds {
                name
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        tournamentId,
      },
    },
  );
  const [deleteTournamentStep] = useMutation(
    gql`
      mutation ($id: ID!) {
        deleteTournamentStep(stepId: $id)
      }
    `,
    {
      onCompleted: () => {
        refetch().then();
      },
    },
  );

  return (
    <>
      <Title>Tournament Steps</Title>
      <Button onClick={() => navigate("new")}>Create a new step</Button>
      <DataTable
        columns={[
          {
            accessor: "name",
            title: "Name",
            render: (item) => (
              <Anchor
                onClick={() => {
                  navigate(`${item.id}`);
                }}
              >
                {item.name}
              </Anchor>
            ),
          },
          {
            accessor: "order",
            title: "Order",
          },
          {
            accessor: "status",
            title: "Status",
          },
          {
            accessor: "type",
            title: "Type",
          },
          {
            accessor: "",
            title: "",
            render: (item: TournamentStep) => (
              <>
                <Tooltip label="Delete item">
                  <ActionIcon
                    color="red"
                    onClick={() =>
                      modals.openConfirmModal({
                        title: "Delete step",
                        id: "delete-step",
                        children: "Are you sure you want to delete this step?",
                        confirmProps: { color: "red" },
                        labels: {
                          cancel: "Cancel",
                          confirm: "Delete",
                        },
                        centered: true,
                        onConfirm: () =>
                          deleteTournamentStep({ variables: { id: item.id } }),
                      })
                    }
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </>
            ),
          },
        ]}
        records={data?.tournamentSteps}
        fetching={loading}
      />
    </>
  );
};
